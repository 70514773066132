
import { useCallback } from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { PlanFamillyList } from "@constants/common";
import { ReallocationDivestmentState } from '@modules/common/types';
import {
  ProfitSharingIncentiveDestinationCardType,
  ProfitSharingIncentiveDestinationSubCardType
} from '@pages/OperationDetails/OperationDetailsPage/components';
export const useCards = () => {
  const { formatMessage, formatDate } = useTranslation();

  const getCards = useCallback(
    (plans:  ReallocationDivestmentState[] | null | undefined, position: 'SOURCE' | 'DESTINATION' | 'CONTRIBUTION') =>
      (plans || []).reduce<{
        cards: ProfitSharingIncentiveDestinationCardType[];
        hasPlanMergedExternal: boolean;
      }>(
        (acc, plan, index) => {
          const isMergedExternal = !!plan?.advancedProperties?.IsMergedExternal;

          const constructedDestinationManagements = plan.compartments.reduce<
            ProfitSharingIncentiveDestinationCardType['managements']
          >((_, compartment) => {
            if (!compartment?.totalAmount) return _;

            return compartment.managements.reduce<
              ProfitSharingIncentiveDestinationCardType['managements']
            >((_constructedDestinationManagements, management) => {
              if (management?.advancedProperties?.IsTechnicalManagement)
                return _constructedDestinationManagements;

              const constructedDestinationSubCards = management.supports.reduce<
                ProfitSharingIncentiveDestinationSubCardType[]
              >((filteredSupports, support, index, array) => {
                const isValidSupport =
                  !support?.advancedProperties?.IsTechnicalFund &&
                  !!support?.advancedProperties.AmountNet;

                if (!isValidSupport) return filteredSupports;

                const isAvailable = support.advancedProperties.IsAvailable;
                const dueDate = isAvailable
                  ? formatDate({
                      value: support.advancedProperties.AvailabilityDate,
                      options: {
                        dateStyle: 'short'
                      }
                    })
                  : formatMessage({ id: support.advancedProperties.AvailabilityLabel });

                const filteredSupport = {
                  title: support.supportName,
                  amount: support?.advancedProperties.AmountNet,
                  percentage: support?.formValues?.amountPercent,
                  netAssetValue: support?.netAssetValue,
                  numberOfUnits: support?.amount.numberOfUnits,
                  dueDate: dueDate,
                  availabilities: support.advancedProperties.Availabilities
                };

                return [...filteredSupports, filteredSupport];
              }, []);

              const constructedDestinationManagement = {
                isFictive: management.isFictive,
                title: management.label,
                isFreeManagement: management.isFree,
                hideManagementRow: management.isFictive,
                amount: management.totalAmount,
                percentage: management.formValues?.amountPercent,
                hasContribution: management.advancedProperties.HasContribution,
                contribution: management.formValues?.contributionAmount,
                showMoreSupportData: plan.planFamily === PlanFamillyList.ES,
                subCards: constructedDestinationSubCards
              };

              return [..._constructedDestinationManagements, constructedDestinationManagement];
            }, []);
          }, []);

          const filetTitle = formatMessage({
            id: position === 'SOURCE' ? 'DetailOperationDeLabel' : 'PIAffectationdefautVersLabel'
          })
          
          const name = plan.isCustomPlan? plan.planFullName : plan.shortLabel;

          const card = {
            filetTitle: (index === 0 && filetTitle) || undefined,
            planName: name,
            planType: plan.planType,
            amount: plan.advancedProperties.AmountNet,
            isMergedExternal,
            managements: constructedDestinationManagements
          };

          return {
            cards: [
              ...acc.cards,
              card
            ],
            hasPlanMergedExternal: acc.hasPlanMergedExternal || isMergedExternal
          };
        },
        {
          cards: [],
          hasPlanMergedExternal: false
        }
      ),
    [formatDate, formatMessage]
  );

  return { getCards }
};
