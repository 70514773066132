import React, {FC} from "react";
import {OperationTotalSubHeader} from "@ere-uilib/molecules";
import {useProfitSharringRepartitionContext} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";
import {useTranslation} from "@components/ERE-UILib";

export const SubHeader: FC = () => {
    const {isPercentage, remainingInvestedPercent, remainingInvestedAmount, hasStartedInvest, } = useProfitSharringRepartitionContext();
    const { formatMessage } = useTranslation();
    return (
        <OperationTotalSubHeader
            isAmountPercent={isPercentage}
            isSwitchEnabled={false}
            mainAmount={isPercentage ?
                remainingInvestedPercent
                : remainingInvestedAmount}
            mainTitle={hasStartedInvest ?
                formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiLabel' })
                : formatMessage({ id: 'PIChoixInvestMontantRepartirLabel' })}
            secondaryAmount={isPercentage ?
                remainingInvestedAmount
                : remainingInvestedPercent}
            secondaryTitle={formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiSoitLabel' })}
        />
    );
}
