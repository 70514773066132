export const allSVGPaths: {
  [key: string]: any; // TODO : find the type to use librarie is not really clear about that
} = {
  init: require('@assets/icons/init.svg').default,
  check: require('@assets/icons/check.svg').default,
  ligthCheck: require('@assets/icons/lightCheck.svg').default,
  connection: require('@assets/icons/connection.svg').default,
  support: require('@assets/icons/support.svg').default,
  info: require('@assets/icons/info.svg').default,
  infoGrey: require('@assets/icons/info-grey.svg').default,
  waitingProcess: require('@assets/icons/img_waiting_process.svg').default,
  imgPlaceholder: require('@assets/icons/img_placeholder.svg').default,
  now: require('@assets/icons/now.svg').default,
  future: require('@assets/icons/future.svg').default,
  retreat: require('@assets/icons/retreat.svg').default,
  unblock: require('@assets/icons/unblock.svg').default,
  home: require('@assets/icons/home-mobile.svg').default,
  homeActive: require('@assets/icons/home-mobile-active.svg').default,
  homeWhite: require('@assets/icons/home.svg').default,
  saving: require('@assets/icons/saving-mobile.svg').default,
  savingActive: require('@assets/icons/saving-mobile-active.svg').default,
  savingWhite: require('@assets/icons/saving.svg').default,
  history: require('@assets/icons/history-mobile.svg').default,
  historyActive: require('@assets/icons/history-mobile-active.svg').default,
  historyWhite: require('@assets/icons/history.svg').default,
  profile: require('@assets/icons/profile-mobile.svg').default,
  profileActive: require('@assets/icons/profile-mobile-active.svg').default,
  profileWhite: require('@assets/icons/profile.svg').default,
  btnMenuActive: require('@assets/icons/btn-menu-active.svg').default,
  btnMenuInactive: require('@assets/icons/btn-menu-inactive.svg').default,
  logoHorizontalDark: require('@assets/icons/logo-horizontal-dark.svg').default,
  logoHorizontalLight: require('@assets/icons/logo-horizontal-light.svg').default,
  logoVerticalDark: require('@assets/icons/logo-vertical-dark.svg').default,
  logoVerticalLight: require('@assets/icons/logo-vertical-light.svg').default,
  tabBg: require('@assets/icons/tab-bg.svg').default,
  logoutWhite: require('@assets/icons/deconnection.svg').default,
  moneybox: require('@assets/icons/moneybox.svg').default,
  moneyboxMobile: require('@assets/icons/moneybox-mobile.svg').default,
  welcome: require('@assets/icons/welcome.svg').default,
  thanks: require('@assets/icons/thanks.svg').default,
  congrats: require('@assets/icons/congrats.svg').default,
  lock: require('@assets/icons/lock.svg').default,
  stepCheck: require('@assets/icons/step-check.svg').default,
  logout: require('@assets/icons/logout.svg').default,
  welcomeGif: { img: require('@assets/icons/welcome_gif.gif'), imgType: 'gif' },
  pdf: require('@assets/icons/pdf.svg').default,
  invitationError: require('@assets/icons/oups.svg').default,
  forget_password_validated: require('@assets/icons/forget_password_validated.svg').default,
  update_contact_preferences_success:
    require('@assets/icons/update_contact_preferences_success.svg').default,
  activeAccount: require('@assets/icons/active-account.svg').default,
  backgroundTop: require('@assets/icons/top_background.svg').default,
  VVP_Icon: require('@assets/icons/VVP_Icon.svg').default,
  backgroundBottom: require('@assets/icons/bottom_background.svg').default,
  greenBackgroundMobile: require('@assets/icons/greenBackgroundMobile.svg').default,
  greenBackgroundDesktop: require('@assets/icons/greenBackgroundDesktop.svg').default,
  warning: require('@assets/icons/warning.svg').default,
  warningWithBackground: require('@assets/icons/warning-withbg.svg').default,
  mobileGreenWave: require('@assets/icons/mobile-green-wave.svg').default,
  accountCreationBackground: require('@assets/icons/account-creation-background.svg').default,
  espagne: require('@assets/icons/espagne.svg').default,
  france: require('@assets/icons/france.svg').default,
  angleterre: require('@assets/icons/angleterre.svg').default,
  allemagne: require('@assets/icons/allemagne.svg').default,
  italie: require('@assets/icons/italie.svg').default,
  portugal: require('@assets/icons/portugal.svg').default,
  chinois: require('@assets/icons/chinois.svg').default,
  franceConnect: require('@assets/icons/franceconnect-bouton.svg').default,
  franceConnectHover: require('@assets/icons/franceconnect-bouton-hover.svg').default,
  robotSapiendo: require('@assets/icons/robot-sapiendo.svg').default,
  robotOad: require('@assets/icons/robot-oad.svg').default,
  carefulOad: require('@assets/icons/careful-oad.svg').default,
  dynamiqueOad: require('@assets/icons/dynamique-oad.svg').default,
  equilibreOad: require('@assets/icons/equilibre-oad.svg').default,
  idea: require('@assets/icons/idea.svg').default,
  installmentDone: require('@assets/icons/installment.svg').default,
  installmentError: require('@assets/icons/installment_error.svg').default,
  profileCongrats: require('@assets/icons/profile_congrats.svg').default,
  greenLabel: require('@assets/icons/green_label.svg').default,
  skipStep: require('@assets/icons/skipStep.svg').default,
  refundDone: require('@assets/icons/refund.svg').default,
  savingsJar: require('@assets/icons/savings-jar.svg').default,
  notchedWheel: require('@assets/icons/notched-wheel.svg').default,
  consent: require('@assets/icons/consent.svg').default,
  loginForm: require('@assets/icons/login-form.svg').default,
  laptopGirl: require('@assets/icons/laptop-girl.svg').default,
  chronoSteps: require('@assets/icons/chrono-steps.svg').default,
  sendEmail: require('@assets/icons/email.svg').default,
  man: require('@assets/icons/homme.svg').default,
  hand: require('@assets/icons/main.svg').default,
  woman: require('@assets/icons/femme_assise.svg').default,
  budgetinsightRedirect: require('@assets/icons/budgetinsight-redirect.svg').default,
  budgetinsightSuccess: require('@assets/icons/budgetinsight-success.svg').default,
  earth: require('@assets/icons/earth.svg').default,
  prime: require('@assets/icons/prime.svg').default,
  cross: require('@assets/icons/cross.svg').default,
  search: require('@assets/icons/recherche.svg').default,
  maj: require('@assets/icons/MAJ.svg').default,
  maintenance: require('@assets/icons/maintenance.svg').default,
  FaceID: require('@assets/icons/FaceID.svg').default,
  TouchID: require('@assets/icons/TouchID.svg').default,
  Biometrics: require('@assets/icons/biometric.svg').default,
  documentTag: require('@assets/icons/document-tag.svg').default,
  longArrow: require('@assets/icons/long-arrow.svg').default,
  rightArrow: require('@assets/icons/arrow-right.svg').default,
  moneyAndCalendar: require('@assets/icons/money-and-calendar.svg').default,
  retirement: require('@assets/icons/retirement.svg').default,
  lecture: require('@assets/icons/lecture.svg').default,
  uploadOk: require('@assets/icons/upload-ok.svg').default,
  uploadError: require('@assets/icons/upload-error.svg').default,
  oupsWithComputer: require('@assets/icons/oups-with-computer.svg').default,
  receipt: require('@assets/icons/justificatifs.svg').default,
};
