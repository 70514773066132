import { FileExtensions } from "@ere-uilib/types";

export const ALLOWED_FILE_EXTENSIONS = [
  FileExtensions.JPEG,
  FileExtensions.JPG,
  FileExtensions.PDF
];

export enum InputMode {
  // DATA = "DATA",
  // UPLOAD = "UPLOAD",
  DATA_AND_UPLOAD = "DATA_AND_UPLOAD",
  SERVICE_RH = "SERVICE_RH",
  TSA = "TSA",
  TSA_OR_UPLOAD = "TSA_OR_UPLOAD",
  CORRESPONDANT = "CORRESPONDANT",
}

export enum BankStatusTypesEnum {
  DEMANDE_EN_COURS = 'DEMANDE_EN_COURS',
  DEMANDE_INITIEE = 'DEMANDE_INITIEE',
  VALIDE = 'VALIDE',
  INVALIDE = 'INVALIDE',
}
