import { PaletteTypes } from './interfaces';

export const palette: PaletteTypes = {
  black: '#000000',
  blackTransparent: {
    c000: '#ffffff00',
    c100: '#0000001a',
    c200: '#ffffff33',
    c300: '#ffffff20',
    c500: '#00000080',
    c700: '#000000b3'
  },
  greyTransparent: {
    c000: '#606170',
    c100: '#60617026',
    c200: 'rgba(15, 15, 15, 0.3)'
  },
  yellowLight_1: {
    c100: '#FDF6F0',
    c500: '#FDDB66'
  },
  yellowLight_2: {
    c100: '#FAEDCC',
    c500: '#E8A602'
  },
  yellowDark_1: {
    c100: '#FDF4E5',
    c500: '#F3B351'
  },
  yellowDark_2: {
    c500: '#EEC16B'
  },
  yellowDark_3: {
    c300: '#F3C651',
    c500: '#D69C0A',
    c700: '#B1591A'
  },
  orangeLight_1: {
    c100: '#FDEEE0',
    c500: '#F28B2D'
  },
  orangeDark_1: {
    c100: '#FBE9E1',
    c500: '#E16937'
  },
  brown_1: {
    c300: '#996B59',
    c500: '#865441'
  },
  blueLight_1: {
    c100: '#F3F6F9',
    c500: '#ADC4DA'
  },
  blueLight_2: {
    c100: '#DCF0EE',
    c500: '#13857B'
  },
  blueLight_3: {
    c100: '#D9E8EE'
  },
  blueDark_1: {
    c100: '#E6EDF4',
    c500: '#5B8AB5'
  },
  blueDark_2: {
    c500: '#0076A9'
  },
  blueDark_3: {
    c300: '#046490',
    c500: '#365E83',
    c600: '#026E7A'
  },
  greenLight_1: {
    c100: '#F4F8EF',
    c500: '#B7CE97',
    c900: '#80C8AC'
  },
  greenLight_2: {
    c100: '#D9EDE6',
    c300: '#82BC68',
    c500: '#078213'
  },
  greenLight_3: {
    c100: '#D9F1E4',
    c200: '#F2F9F7',
    c300: '#E6F3EE',
    c500: '#00A44B'
  },
  greenDark_1: {
    c100: '#D9EFE6',
    c200: '#DBECDB',
    c300: '#008859',
    c400: '#04AF6E',
    c500: '#008859',
    c600: '#00915A'
  },
  greenDark_2: {
    c100: '#D9E6E2',
    c500: '#005740'
  },
  greenDark_3: {
    c100: '#E6F4EF',
    c500: '#22543A'
  },
  greenDark_4: {
    c500: '#2CA4BE'
  },
  greenSoft_1: {
    c200: '#ADD4B8',
    c300: '#71C7A5',
    c500: '#26AA90',
    c600: '#EDF6E9',
  },
  greenTransparent: {
    c100: '#00915A10'
  },
  purpleDark_1: {
    c500: '#B46B95'
  },
  purpleDark_2: {
    c100: '#EAE5EF',
    c500: '#8C539A'
  },
  purpleLight_1: {
    c500: '#934672'
  },
  pinkLight_1: {
    c100: '#F5E3E6',
    c500: '#B33E54'
  },
  pinkDark_1: {
    c500: '#CA5569'
  },
  pinkDark_2: {
    c100: '#F1DFE3',
    c500: '#BA4057'
  },
  redDark_1: {
    c100: '#F5E1DA',
    c500: '#BD3409'
  },
  redLight: {
    c100: '#FEF5F7'
  },
  purpleRed: {
    c100: '#BA4057'
  },
  red: '#BA4057',
  grey: {
    c100: '#F5F5F5',
    c200: '#E7E7E7',
    c300: '#C8C8C8',
    c400: '#9A9A9A',
    c500: '#686767',
    c600: '#555555',
    c700: '#686767',
    c800: '#F3F3F3',
    c900: '#0F0F0F',
    text: 'rgba(15, 15, 15, 0.3)'
  },
  white: '#FFFFFF',
  greyPurple: '#5e5e72',
  franceConnect: {
    link: '#034EA2'
  }
}
