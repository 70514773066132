import React, { useCallback } from 'react'
import { View } from 'react-native'

import { Text } from '@ere-uilib/atoms'
import { SynthesisFundsRow, SynthesisManagementRow } from '@ere-uilib/molecules'
import { useTranslation } from '@ere-uilib/translations'

import { useStyles } from './styles'
import { SimpleCardProps, ThresholdDataProps } from './types'

export function FiletStepperSimpleCard({
  title,
  amount,
  headerColor,
  isFreeManagement,
  isFlowReallocation,
  subCards,
  hideManagementRow,
  percentage,
  hasContribution,
  contribution,
  showMoreSupportData,
  conditionalOrder,
  statusCode,
  renderTriggerTreshold
}: SimpleCardProps) {
  const { formatMessage } = useTranslation()

  const styles = useStyles()

  const onTriggerThresholdRender = useCallback(
    (data: ThresholdDataProps) => {
      if (!renderTriggerTreshold) return

      return renderTriggerTreshold(data)
    },
    [renderTriggerTreshold]
  )

  const renderEcheanceValue = useCallback(
    (echeanceValue: string | undefined) => {
      if (!echeanceValue) return null

      return (
        <View style={styles.echeanceContainerRow}>
          <Text
            variant="t3"
            weight="light">
            {`${formatMessage({ id: 'MaturityArbitrageFeatureChosenMaturity' })}`}
          </Text>
          <Text
            variant="t3"
            weight="light">
            {echeanceValue}
          </Text>
        </View>
      )
    },
    [formatMessage]
  )
  return (
    <>
      {!hideManagementRow && (
        <SynthesisManagementRow
          amount={!isFlowReallocation ? amount : undefined}
          containerStyle={{
            backgroundColor: headerColor
          }}
          contribution={contribution}
          hasContribution={hasContribution}
          hideManagementAmount={isFreeManagement}
          hideManagementOnlyAmount={isFlowReallocation}
          percentage={percentage}
          title={title}
        />
      )}
      {subCards?.map((subCard, i) => {
        return (
          <>
            <SynthesisFundsRow
              amount={!isFlowReallocation ? subCard.amount : undefined}
              conditionalOrder={conditionalOrder}
              contribution={subCard.contribution}
              hasContribution={subCard.hasContribution}
              key={i}
              netAssetValue={subCard.netAssetValue}
              numberOfUnits={subCard.numberOfUnits}
              onTitlePress={subCard.onPress}
              percentage={subCard.percentage}
              showMoreSupportData={showMoreSupportData}
              statusCode={statusCode}
              title={subCard.title}
              availabilityItems={subCard.availabilities}
            />
            {subCard.triggerThreshold && onTriggerThresholdRender(subCard.triggerThreshold)}
            {renderEcheanceValue(subCard.echeanceValue)}
          </>
        )
      })}
    </>
  )
}
