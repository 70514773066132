import { StackNavigationOptions } from '@react-navigation/stack'

import { useTranslation } from '@ere-uilib/translations'

export const useDefaultScreensOptions = () => {
  const { formatMessage } = useTranslation()

  const commonOptions: StackNavigationOptions = {
    animationEnabled: false,
    title: formatMessage({ id: 'website_title' }),
    cardStyle: {
      backgroundColor: 'white'
    }
  }

  const modalOptions: StackNavigationOptions = {
    title: formatMessage({ id: 'website_title' }),
    presentation: 'transparentModal'
  }

  return {
    PersoneoIdentifierForm: {
      ...modalOptions
    },
    VisiogoIdentifierForm: {
      ...modalOptions
    },
    OperationsModalPage: {
      ...modalOptions
    },
    ConfirmModal: {
      ...modalOptions
    },
    MaintenanceModal: {
      ...modalOptions
    },
    ActivateAccountModeSelectionPage: {
      ...commonOptions
    },
    VVPList: {
      ...commonOptions
    },
    CguAcceptation: {
      ...commonOptions
    },
    OidcCallbackRedirect: {
      ...commonOptions
    },
    OidcLogoutCallbackRedirect: {
      ...commonOptions
    },
    NativeLoginPage: {
      ...commonOptions
    },
    SelfcareLetterLanding: {
      ...commonOptions
    },
    SelfcareLetterSuccess: {
      ...commonOptions
    },
    SelfcarePersoneoSuccessPage: {
      ...commonOptions
    },
    SelfcareVisiogoSuccessPage: {
      ...commonOptions
    },
    LetterIdentifierForm: {
      ...commonOptions
    },
    OtpValidation: {
      ...modalOptions
    },
    OtpValidationStart: {
      ...modalOptions
    },
    OtpValidationSuccess: {
      ...modalOptions
    },
    OtpValidationForm: {
      ...modalOptions
    },
    OtpSecurisation: {
      ...modalOptions
    },
    OtpSecurisationStart: {
      ...modalOptions
    },
    OtpSecurisationForm: {
      ...modalOptions
    },
    Error: {
      ...commonOptions
    },
    Logout: {
      ...commonOptions
    },
    EndOidcSessionPage: {
      ...commonOptions
    },
    EndOidcSessionCallbackPage: {
      ...commonOptions
    },
    CompanyChoicePage: {
      ...commonOptions
    },
    Support: {
      ...commonOptions
    },
    RelationshipEntringProcess: {
      ...commonOptions
    },
    Welcome: {
      ...commonOptions
    },
    ConsentTransferCredit: {
      ...commonOptions
    },
    ValidateSignup: {
      ...commonOptions
    },
    ValidationCodePage: {
      ...commonOptions
    },
    ValidateSignupThankYou: {
      ...commonOptions
    },
    ChooseIdentificationMail: {
      ...commonOptions
    },
    ChooseIdentificationPhone: {
      ...commonOptions
    },
    ChooseIdentificationEmailCode: {
      ...commonOptions
    },
    ChooseIdentificationPhoneCode: {
      ...commonOptions
    },
    ChoosePassword: {
      ...commonOptions
    },
    AccountCreationSuccess: {
      ...commonOptions
    },
    AccountTerms: {
      ...commonOptions
    },
    CompanyBenefits: {
      ...commonOptions
    },
    OnboardingPersoneo: {
      ...commonOptions
    },
    OnboardingVisiogo: {
      ...commonOptions
    },
    ForgetPassword: {
      ...commonOptions
    },
    ForgetPasswordBegin: {
      ...commonOptions
    },
    ForgetPasswordValidationCodePage: {
      ...commonOptions
    },
    ForgetPasswordChoosePasswordPage: {
      ...commonOptions
    },
    ForgetPasswordCongratsPage: {
      ...commonOptions
    },
    BottomTabNavigator: {
      ...commonOptions
    },
    Home: {
      ...commonOptions
    },
    Savings: {
      ...commonOptions
    },
    funds: {
      ...commonOptions
    },
    VVPDetails: {
      ...commonOptions
    },
    OperationDetails: {
      ...commonOptions
    },
    OperationCancel: {
      ...modalOptions
    },
    OperationCancelSuccess: {
      ...modalOptions
    },
    BiConsentment: {
      ...commonOptions
    },
    BiErrorPage: {
      ...commonOptions
    },
    BiConnect: {
      ...commonOptions
    },
    BiCallback: {
      ...commonOptions
    },
    History: {
      ...commonOptions
    },
    Profile: {
      ...commonOptions
    },
    ProfileEditMail: {
      ...commonOptions
    },
    ProfileEditPhone: {
      ...commonOptions
    },
    EditorialPage: {
      ...commonOptions
    },
    LegalInfoPage: {
      ...commonOptions
    },
    EditProfileInformationsPage: {
      ...commonOptions
    },

    BankSetDomiciliationPage: {
      ...commonOptions
    },
    BankSetDocumentsPage: {
      ...commonOptions
    },
    BankSummaryPage: {
      ...commonOptions
    },
    BankSetIbanPage: {
      ...commonOptions
    },
    BankCopyIbanPage: {
      ...commonOptions
    },
    BankSignaturePage: {
      ...commonOptions
    },
    BankSignaturePageRC: {
      ...commonOptions
    },
    BankDetailsTypeModalPage: {
      ...modalOptions
    },
    ContactPreferencesPage: {
      ...commonOptions
    },
    BusinessContactPreferencesPage: {
      ...commonOptions
    },
    ConnexionBiometrique: {
      ...commonOptions
    },
    EditPasswordPage: {
      ...commonOptions
    },
    MobileMenuModal: {
      ...commonOptions
    },
    NotFound: {
      ...commonOptions
    },
    BackOfficeAccess: {
      ...commonOptions
    },
    DisponibilityTermPage: {
      ...commonOptions
    },
    FConnectSignUp: {
      ...commonOptions
    },
    SapiendoRedirectPage: {
      ...commonOptions
    },
    Installments: {
      ...commonOptions
    },
    InstallmentsRepartition: {
      ...commonOptions
    },
    InstallmentsSynthesis: {
      ...commonOptions
    },
    InstallmentsPaymentMode: {
      ...commonOptions
    },
    InstallmentsRequirementDefiniton: {
      ...commonOptions
    },
    InstallmentPlanChoicePage: {
      ...commonOptions
    },
    InstallmentFailedPage: {
      ...commonOptions
    },
    InstallmentSuccessPage: {
      ...commonOptions
    },
    InstallmentLoadingPage: {
      ...commonOptions
    },
    InstallmentPaymentCBHtmlPage: {
      ...commonOptions
    },
    RedirectOperationPage: {
      ...commonOptions
    },
    Refunds: {
      ...commonOptions
    },
    RefundRepartition: {
      ...commonOptions
    },
    RefundsRequirementDefiniton: {
      ...commonOptions
    },
    UnavailableRefundRC: {
      ...commonOptions
    },
    RefundOnboarding: {
      ...commonOptions
    },
    RefundsPlanChoice: {
      ...commonOptions
    },
    RefundAskProof: {
      ...commonOptions
    },
    RefundsRecap: {
      ...commonOptions
    },
    RefundSuccess: {
      ...commonOptions
    },
    RefundFailed: {
      ...commonOptions
    },
    Arbitration: {
      ...commonOptions
    },
    ArbitrationChoice: {
      ...commonOptions
    },
    ArbitrationPlanChoice: {
      ...commonOptions
    },
    ArbitrationOnboarding: {
      ...commonOptions
    },
    ArbitrationDisinvestmentsSaving: {
      ...commonOptions
    },
    ArbitrationReinvestment: {
      ...commonOptions
    },
    ArbitrationSynthesis: {
      ...commonOptions
    },
    ArbitrationFailure: {
      ...commonOptions
    },
    ArbitrationSuccess: {
      ...commonOptions
    },
    ArbitrationOADRepartition: {
      ...commonOptions
    },
    ArbitrationOADRepartitionConfirmation: {
      ...commonOptions
    },
    ProfitSharingIncentive: {
      ...commonOptions
    },
    ProfitSharingIncentiveAssigment: {
      ...commonOptions
    },
    ProfitSharingIncentiveDescription: {
      ...commonOptions
    },
    ProfitSharingIncentiveOnboarding: {
      ...commonOptions
    },
    ProfitSharingIncentiveRepartition: {
      ...commonOptions
    },
    ProfitSharingIncentiveSynthesis: {
      ...commonOptions
    },
    ProfitSharingIncentiveSuccess: {
      ...commonOptions
    },
    ProfitSharingIncentiveDefaultAffectations: {
      ...commonOptions
    },
    ProfitSharingIncentiveFailedPage: {
      ...commonOptions
    },
    DocumentSpace: {
      ...commonOptions
    },
    DocumentSpaceHome: {
      ...commonOptions
    },
    DocumentSpaceFiles: {
      ...commonOptions
    },
    DocumentSpaceEDocumentsFiles: {
      ...commonOptions
    },
    DocumentSpaceEDocumentsSubscription: {
      ...commonOptions
    },
    DocumentSpaceGeneralDocuments: {
      ...commonOptions
    },
    EStatementFiles: {
      ...commonOptions
    },
    DocumentSpaceViewer: {
      ...commonOptions
    },
    Retirement: {
      ...commonOptions
    },
    RetirementOnboardingPage: {
      ...commonOptions
    },
    DetailsPage: {
      ...commonOptions
    },
    RetirementPage: {
      ...commonOptions
    },
    RetirementRecoverSavingPage: {
      ...commonOptions
    },
    StorybookPage: {
      ...commonOptions,
      cardStyle: {
        backgroundColor: 'white'
      }
    },
    Annuity: {
      ...commonOptions
    },
    BankDetails: {
      ...commonOptions
    },
    ContributionModal: {
      ...modalOptions
    },
    ControlData: {
      ...commonOptions
    },
    MaintenancePage: {
      ...commonOptions
    },
    VotAccess: {
      ...commonOptions
    }
  }
}
