import { get } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import {
  OtpChanelTypeEnum,
} from '@constants/index';
import { API_ERROR_CODE, API_ERROR_STATUS, EDIT_USER_INFOS_SECURE_AUTHENTICATION } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { AuthActionType } from '@modules/auth/actions/authActionsTypes';
import { ForgetPasswordActionsType } from '@modules/forget-password/actions/forgetPasswordActionsTypes';
import { InvitationActionsType } from '@modules/invitation/actions/invitationActionsTypes';
import { PendingTasksActionsType } from '@modules/pending-tasks/actions/pendingTasksActionsTypes';
import {
  ProfileActionsType,
} from '@modules/profile/actions/profileActionsTypes';
import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { RootNavigation } from '@navigation/RootNavigation';

import { OtpParametersState } from '@modules/profile/types';
import { otpFlowStartRequest } from '@modules/profile/actions/profileActions';
import { getSelectedDocaposteChanel } from './bank-details/selectors';
import { logoutCallbackSuccess } from '@modules/auth/actions/authActions';
import { onSetMaintenanceContent } from '@modules/settings/actions/settingsActions';

export function* setCallAuthorizationToken(apiCall: any, ...args: any): any {
  const response = yield call(apiCall, ...args);
  return response;
}

export function authenticatedCall(apiCall: any, ...args: any) {
  return call(setCallAuthorizationToken, apiCall, ...args);
}

export function* catchApiExceptions(actionType: any, error: any, action?: any): any {
  const decodedError = get(error, 'response');
  const htmlContentRegex: RegExp = /<[a-z][\s\S]*/i;

  // Set default values in case all keys are not filled in
  let exceptedError = {
    code: 'ERR',
    correlationId: '',
    message: 'Server Error',
    innerMessage: 'Server Error',
    ...decodedError?.data,
  };

  if (
    error?.response?.status === 503
    && htmlContentRegex.test(error?.response?.data)
  ) {
    yield put(onSetMaintenanceContent(error?.response?.data));
    yield put(logoutCallbackSuccess())
    return yield RootNavigation?.replace(RouteNames.MaintenancePage);
  }

  if (
    actionType === InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE &&
    exceptedError.code === API_ERROR_CODE.ERR_REG_15
  ) {
    yield RootNavigation.replace(RouteNames.SelfcareStack, {
      screen: RouteNames.SelfcareLetterSuccess,
    });
  }

  // errors that means that back session has been expired , redirecting to logout page
  if (decodedError?.data?.Code === API_ERROR_CODE.SESSION_EXPIRED) {
    RootNavigation.replace(RouteNames.Logout)
  }
  // manage redirection when no compaies
  if (
    actionType === DashboardActionsType.GET_ACCOUNTS_FAILURE ||
    actionType === AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE ||
    actionType === ProfileActionsType.GET_CONSENTS_FAILURE ||
    actionType === DashboardActionsType.GET_ACCOUNTS_DATA_FAILURE ||
    actionType === PendingTasksActionsType.GET_PENDING_TASKS_FAILURE
  ) {
    RootNavigation.replace(RouteNames.Error, {
      errorDescriptionLabel: 'login_erreur_generique_label',
      redirectionRouteName: RouteNames.Logout,
    });
  }
  // force redirection to otp page in forgetPasswordProcess even if the login is wrong (security requirement)
  if (actionType === ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_FAILURE) {
    //@todo: redirect to otp page
    return yield RootNavigation.navigate(RouteNames.ForgetPassword, {
      screen: RouteNames.ForgetPasswordValidationCodePage,
      params: {
        isForgetPasswordPhoneNumberVerify: false,
      },
    });

  }
  // redirect to page error fi init France connect api is KO
  if (actionType === ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE) {
    yield RootNavigation.replace(RouteNames.Error, {
      redirectionRouteName: RouteNames.RelationshipEntringProcess,
      redirectionRouteParams: {
        screen: RouteNames.ValidateSignup,
      },
      errorTitleLabel: 'validate-identity_FCerror_title',
      errorDescriptionLabel: 'validate-identity_FCerror_label',
      errorButtonTitleLabel: 'validate-identity_FCerror_button',
    });
  }
  // manage redirection to error page when invitation token is not valid
  if (actionType === InvitationActionsType.GET_INVITATION_FAILURE) {
    yield RootNavigation.replace(RouteNames.Error, {
      redirectionRouteName: RouteNames.OidcCallbackRedirect,
      errorTitleLabel: 'error_title',
      errorDescriptionLabel: 'error_content',
      errorButtonTitleLabel: 'return_login_button',
    });
  }
  // errors that have spécific message
  if (
    (!exceptedError?.code && !exceptedError?.Code) && (
      decodedError?.status === API_ERROR_STATUS.NOT_FOUND_STATUS
      || decodedError?.status === API_ERROR_STATUS.BAD_REQUEST
    )
  ) {
    exceptedError = {
      code: decodedError.status,
      correlationId: '',
      message: 'Server Error',
      innerMessage: 'Server Error',
    };
  }

  // manage validate profile info change security exception
  if (
    [EDIT_USER_INFOS_SECURE_AUTHENTICATION.SMS_OTP_REQUIRED,
    EDIT_USER_INFOS_SECURE_AUTHENTICATION.EMAIL_OTP_REQUIRED,
    EDIT_USER_INFOS_SECURE_AUTHENTICATION.BOTH_CHANEL_OTP_REQUIRED,
    EDIT_USER_INFOS_SECURE_AUTHENTICATION.DOCAPOSTE_OTP_REQUIRED].includes(exceptedError.Code ?? exceptedError.code)
  ) { // todo: add case of secure phonenumber ans unknow secure (email or phone)
    const docaposteOtpChanel = yield select(getSelectedDocaposteChanel)
    let chanelType;
    switch (exceptedError.Code ?? exceptedError.code) {
      case EDIT_USER_INFOS_SECURE_AUTHENTICATION.SMS_OTP_REQUIRED:
        chanelType = OtpChanelTypeEnum.SMS;
        break;
      case EDIT_USER_INFOS_SECURE_AUTHENTICATION.EMAIL_OTP_REQUIRED:
        chanelType = OtpChanelTypeEnum.MAIL;
        break;
      case EDIT_USER_INFOS_SECURE_AUTHENTICATION.DOCAPOSTE_OTP_REQUIRED:
        chanelType = docaposteOtpChanel;
        break;
      default:
        chanelType = OtpChanelTypeEnum.SMS; //when both chanel type otp required
        break;
    }
    //init errors when mfaRequired (dsplaying errors en interface not needed)
    exceptedError = {
      code: '',
      correlationId: '',
      message: '',
      innerMessage: '',
    };
    const otpParameters: OtpParametersState = {
      requestType: OtpRequestTypeEnum.SECURE,
      outputActionType: action[0]?.type,
      chanelType: chanelType,
      outputActionParameters: action[0], // todo: pass it as params from sagas to runManger and then to catchapiexception
    };
    yield put(otpFlowStartRequest(otpParameters));
  }

  return yield put({ type: actionType, error: exceptedError });
}
