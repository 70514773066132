import React, { useCallback } from 'react'
import { View } from 'react-native'

import { FontIcon, Link, Text, Title } from '@ere-uilib/atoms'
import { IconEnum } from '@ere-uilib/enums'
import { InputEditAmmont } from '@ere-uilib/molecules'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@translations/index'

import { InputEditAmountWithLinkAndContributionProps } from './interfaces'
import { useStyles } from './useStyles'
import { EstimatedContribution } from '@ere-uilib/organisms'

export const InputEditAmountWithLinkAndContribution = ({
  hasDarkBackground,
  disabled,
  editable,
  isSelected,
  amount,
  onEditAmountPress,
  fieldAmount,
  fieldPercent,
  isFieldAmountPercentage,
  linkUnderfieldLabel,
  isLinkUnderfieldAlwaysEnabled,
  isLinkUnderField,
  onLinkUnderFieldPress,
  onResetAmountPress,
  isContributionDisplayed,
  isContributionLast,
  isContributionError,
  isPercentagePlusAmount,
  contribution = undefined,
  style,
  inputContainerStyle,
  inputContributionStyle,
  amountTextStyle,
  underFieldLinkStyle,
  testId,
  showSign = true
}: InputEditAmountWithLinkAndContributionProps) => {
  const styles = useStyles({
    disabled,
    style
  })

  const { formatCurrencyNumber, formatPercentNumber } = useTranslation()

  const renderLinkUnderField = useCallback(() => {
    if (!isLinkUnderField) {
      return null
    }
    return (
      <Link
        onPress={onLinkUnderFieldPress}
        textStyle={[styles.underFieldLinkStyle, underFieldLinkStyle]}
        textVariant="t3">
        {linkUnderfieldLabel}
      </Link>
    )
  }, [isLinkUnderField, onLinkUnderFieldPress, linkUnderfieldLabel, styles, underFieldLinkStyle])

  const renderContribution = useCallback(() => {
    if (!isContributionDisplayed) {
      return null
    }
    return (
      <EstimatedContribution
        contribution={contribution}
        isContributionError={isContributionError}
        style={inputContributionStyle}
      />
    )
  }, [isContributionDisplayed, isContributionError, contribution, inputContributionStyle])
  const renderRightAmountZone = useCallback(() => {
    if (editable) {
      return (
        <View>
          <InputEditAmmont
            amount={fieldAmount}
            hasDarkBackground={hasDarkBackground}
            isDisabled={!isSelected && !editable}
            isExtended={isPercentagePlusAmount}
            isPercentage={isFieldAmountPercentage}
            onPress={onEditAmountPress}
            onResetPress={onResetAmountPress}
            percent={fieldPercent}
            showSign={showSign}
            style={inputContainerStyle}
            testId={testId}
          />
          {!isContributionLast && renderContribution?.()}
          {(isLinkUnderfieldAlwaysEnabled || editable) && !!fieldAmount && renderLinkUnderField?.()}
          {isContributionLast && renderContribution?.()}
        </View>
      )
    } else if (!!amount || !!fieldPercent) {
      return (
        <View>
          {(typeof amount === 'number' || typeof fieldPercent === 'number') && (
            <Title
              style={[styles.amountTextStyle, amountTextStyle]}
              testId={testId}
              variant="t6"
              weight="bold">
              {isFieldAmountPercentage
                ? formatPercentNumber({ value: fieldPercent || 0 })
                : formatCurrencyNumber({ value: amount || 0 })}
            </Title>
          )}
        </View>
      )
    }
  }, [
    editable,
    amount,
    fieldAmount,
    isSelected,
    isPercentagePlusAmount,
    isFieldAmountPercentage,
    onEditAmountPress,
    onResetAmountPress,
    fieldPercent,
    isContributionLast,
    renderContribution,
    isLinkUnderfieldAlwaysEnabled,
    renderLinkUnderField,
    styles,
    inputContainerStyle,
    amountTextStyle,
    testId,
    formatPercentNumber,
    formatCurrencyNumber
  ])

  return <View style={styles.style}>{renderRightAmountZone()}</View>
}
