import parsePhoneNumber from 'libphonenumber-js';

export const usePhoneNumberUtils = ()=>{
  // created as a hook for later uses of current language code
  const getNumberInfos = (phoneNumber:string)=>{
    // until next us about phone number country selection we use FR as base country
    const parsedPhoneNumberData = parsePhoneNumber(phoneNumber, 'FR');

    return {
      country: parsedPhoneNumberData?.country,
      parsedPhoneNumber: parsedPhoneNumberData?.number,
      formatedPhoneNumber: parsedPhoneNumberData?.formatInternational(),
      isValid: parsedPhoneNumberData?.isValid(),
    };
  };

  // insert here new phoneNumber utils functions if needed

  return {
    getNumberInfos,
  };
};
