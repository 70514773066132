import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import {
  getAccountsSelector,
  getUsedCompanyId,
  getSavingsPlans,
} from '@modules/dashboard/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';
import {
  getRepartitionsRequest,
  getRepartitionsByPlanRequest,
  getHistoryByPlanRequest,
  getAvailabilitiesDetailRequest,
  getAvailabilitiesByPlanDetailRequest,
  getHistoryRequest,
  RedirectToSavings,
  getSavingsHistoryByPlanRequest,
  getSavingsHistoryRequest,
  getOnGoindVVPHistoryRequest,
  applySavingsHistoryFilterRequest,
  setRepartitionFilter,
  getAvailabilitiesFundsByPlanRequest,
  getAvailabilitiesFundsRequest
} from '@modules/savings/actions/savingsActions';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';
import { getInstallmentsRequest } from '@modules/savings/actions/savingsActions';
import {
  getRepartitions,
  getOperationsHistory,
  getAvailabilitiesDetail,
  getOperationsHistoryFilterRoules,
  getSelectedTab,
  getSelectedPlan,
  getSavingsHistory,
  getSavingsHistoryFilters,
  getOnGoingVVPHistory,
  getinstallments,
  getAvailabilitiesFunds
} from '@modules/savings/selectors';

import Saving from './Saving.component';

const mapStateToProps = (state: AppState) => {
  const onGoingVVPHistory = getOnGoingVVPHistory(state);
  const hasOnGoingVVPHistory = !!onGoingVVPHistory && onGoingVVPHistory.length > 0;

  return {
    accounts: getAccountsSelector(state),
    repartitions: getRepartitions(state),
    savingsPlans: getSavingsPlans(state),
    operationsHistory: getOperationsHistory(state),
    selectedCompanyId: getUsedCompanyId(state),
    availabilitiesDetail: getAvailabilitiesDetail(state),
    availabilitiesFunds: getAvailabilitiesFunds(state),
    selectedTab: getSelectedTab(state),
    selectedPlan: getSelectedPlan(state),
    savingsHistory: getSavingsHistory(state),
    savingsHistoryFilters: getSavingsHistoryFilters(state),
    installments: getinstallments(state),
    isRepartitionsApiLoading:
      state.isLoading[SavingsActionsType.GET_REPARTITIONS_REQUEST],
    isRepartitionsByPlansApiLoading:
      state.isLoading[SavingsActionsType.GET_REPARTITIONS_BY_PLAN_REQUEST],
    isOperationsHistoryApiLoading:
      state.isLoading[SavingsActionsType.GET_OPERATIONS_HISTORY_REQUEST],
    isSavingsEvolutionLoading:
      state.isLoading[SavingsActionsType.GET_SAVINGS_HISTORY_REQUEST],
    isSavingsEvolutionByPlanLoading:
      state.isLoading[SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_REQUEST],
    isOperationsHistoryByPlansApiLoading:
      state.isLoading[
        SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST
      ],
    isAvailabilitiesApiLoading:
      state.isLoading[SavingsActionsType.GET_AVAILABILITIES_DETAILS_REQUEST],
    isAvailabilitiesByPlansApiLoading:
      state.isLoading[
        SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST
      ],
    isInstallmentApiLoading:
      state.isLoading[SavingsActionsType.GET_INSTALLMENTS_REQUEST],
    isSavingsHistoryByPlanApiLoading:
      state.isLoading[SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_REQUEST],
    appliedOperationsHistoryFilterRoules:
      getOperationsHistoryFilterRoules(state),
    appSettings: getApplicationSettings(state),
    hasOnGoingVVPHistory,
    isFetchingOnGoingVVP: state.isLoading[SavingsActionsType.GET_ONGOING_VVP_HISTORY_REQUEST],
  };
};

const mapDispatchToProps = {
  onGetRepartitionsByPlan: getRepartitionsByPlanRequest,
  onGetRepartitions: getRepartitionsRequest,
  onGetOperationsHistory: getHistoryRequest,
  onGetOperationsHistoryByPlan: getHistoryByPlanRequest,
  onGetAvailabilitiesDetail: getAvailabilitiesDetailRequest,
  onGetAvailabilitiesDetailByPlan: getAvailabilitiesByPlanDetailRequest,
  onGetAvailabilitiesFunds: getAvailabilitiesFundsRequest,
  onGetAvailabilitiesFundsByPlan: getAvailabilitiesFundsByPlanRequest,
  getInstallments: getInstallmentsRequest,
  onRedirectToSavings: RedirectToSavings,
  onGetSavingsHistoryByPlan: getSavingsHistoryByPlanRequest,
  onGetSavingsHistory: getSavingsHistoryRequest,
  getOnGoingVVPHistory: getOnGoindVVPHistoryRequest,
  onApplySavingsHistoryFilter: applySavingsHistoryFilterRequest,
  onRepartitionFilterChange: setRepartitionFilter,
};

export const SavingsPage = connect(mapStateToProps, mapDispatchToProps)(Saving);
