export const locator = {
  _login: {
    _title: "connect_welcome",
    _connect: "connect", // testId=${nameOfCompany}:title or description
    _select_language: "select_language",
    _personal_space: "personal_space",
    _email_field: "email_field",
    _password_field: "password_field",
    _login_button: "login_button",
    _connection_error: "connection_error",
    _forgot_password_link: "forgot_password_link",
    _refuse_biometric_button: "refuse_biometric_button",
    _accept_biometric_button: "accept_biometric_button",
  },
  _account_activation: {
    _title: "account_activation",
    _courriel: "courriel",
    _personeo: "personeo",
    _visiogo: "visiogo",
    _help_link: "help_link",
    _receive_code: "receive_code",
    _confirm: "confirm",
    _create_account: "create_account",
    _email_adress: "email_adress",
    _confirm_user_name: "confirm_my_user_name",
    _phone_number: "phone_number",
    _save_phone: "save_phone"
  },
  _forgot_password: {
    _title: "forgot_password_title",
    _email: "forgot_password_email",
    _paragraph: "forgot_password_paragraph",
    _send_email_to_reset: "send_email_to_reset",
    _problem_with_email: "problem_with_email",
    _new_password: "new_password",
    _confirm_new_password: "confirm_new_password",
    _confirm_password_button: "confirm_password_button"
  },
  _choose_company: {
    _title: "choose_company",
    _total_amount: "total_amount",
    _company_amount: ":amount" //testId=${nameOfCompany}:amount
  },
  _tab_bar: {
    _home: "home",
    _savings: "savings",
    _my_activity_life: "my_activity_life",
    _profile: "profile",
    _more_options: "more_options"
  },
  _dashboard: {
    _remaining_savings: {
      title: "Dashboard_CardDisponibility_Title_testId"
      // [
      // testId=`${label}_title`
      // testId=`${label}_amount`
      // ]
    },
    _your_other_savings: {
      _title: "your_other_savings",
      // [
      // testId=`your_other_savings_row:${rowIndex}_cell:${cellIndex}`
      // ]
    },
    _my_employer_top: {
      _title: "my_employer_top",
      _description: "my_employer_top_description"
    }
  },
  _profile: {
    _title: "menu_profile",
    _username: "profile_username",
    _last_connexion: "last_connexion",
    _company_name: "company_name",
    _logout: "logout",
    // idOfItems= [urls]
    _personal_details: {
      _title: "personal_details",
      _email_value: "email_value",
      _email_edit: "email_edit",
      _phone_value: "phone_value",
      _phone_edit: "phone_edit",
      _address_status: "_address_status",
      _address_ES: "_address_ES",
      _address_ES_Edit: "_address_ES_Edit",
      _address_RC: "_address_RC",
      _address_RC_Edit: "_address_RC_Edit",
    },
    _contact_preferences: {
      _title: "contact_preferences",
      _receive_notification: "receive_transaction_notification",
      _dont_receive_notification: "dont_receive_transaction_notification",
      _receive_by_email: "receive_by_email",
      _receive_by_message: "receive_by_message",
      _register_my_prefernces: "register_my_prefernces"
    },
    _change_password: {
      _title: "change_password",
      _current_password: "current_password",
      _new_password: "new_password",
      _confirm_password: "confirm_password",
      _save: "save_confirm_new_password"
    },
    _your_preferences: {
      _title: "your_preferences",
      _receive_offers_bnp: "receive_offers_from_bnp_group",
      _do_not_receive_offers_bnp: "do_not_receive_offers_from_bnp_group",
      _receive_offers_parteners: "receive_offers_from_parteners",
      _do_not_receive_offers_parteners: "do_not_receive_offers_from_parteners",
      _save_preferences: "save_preferences"
    },
    _piloted_data: {
      _pen_edit: "pen_edit_card",
      _item_title: "item_title",
      _save_piloted_data: "save_piloted_data",
      _go_back: "go_back"
    }
  },
  _my_savings: {
    _title: "my_savings_rendered_title",
    _right_navigation_icon: "navigate_droite",
    _left_navigation_icon: "navigate_gauche",
    _savings_total_amount: "my_savings_amount",
    _potentiel_gain_loss: "my_savings_potentiel_gain_loss",
    _date_selector: "payments_items_total",
    // items navigation => testId={`savings_linkBarListItem_`${index}} index=[0,1,2,3,4]
    // items list =>
    // title: testId={`title_item_card:${index}`}
    // amount: testId={`amount_item_card:${index}`}
    _history: {
      // history_bloc_${index}
      // history_date_${index}
      // history_amount_${index}
      // history_link_${index}
    }
  },
  _payment: {
    _lets_go: "lets_go",
    _allocate_my_payment: "allocate_my_payment",
    _i_understand: "i_understand",
    _set_modal: "set_modal",
    _confirm_and_continue: "confirm_and_continue",
    _confirm_my_payment: "confirm_my_payment",
    _accept_consent: "accept_consent",
    _confirm_my_payment_synthese: "confirm_my_payment_synthese",
    _back_home: "back_home"
  },
  _vvp_details: {
    _creation_date: "creation_date",
    _plan_title: "plan_title",
    _payment_amount: "payment_amount"
  },
  _arbitration:{
    _lets_go: "lets_go",
    _i_understand: "i_understand",
    _confirm_and_continue: "confirm_and_continue",
    _confirm_new_allocation: "confirm_new_allocation",
    _consent_check_box: "check_box_consent",
    _consent_accept: "consent_accept",
    _compartment_amount: "compartment_amount",
    _synthese_validate: "synthese_validate",
    _back_home: "back_home",
    _oad_continue: "oad_continue"
  },
  _pi:{
    _lets_go: "lets_go",
    _start: "start",
    _continue: "continue",
    _invest_field: "invest_field",
    _receive_field: "receive_field",
    _synthese_continu: "synthese_continu",
    _checkbox: "checkbox",
    _accept_consent: "accept_check_box",
    _validate_affectation: "validate_affectation",
    _back_home: "back_home",
  },
  _recover_savings:{
    _lets_go: "lets_go",
    _enter_request: "enter_request",
    _continue: "continue",
    _confirm: "confirm_and_continue",
    _justificatif_continue: "justificatif_continue",
    _synthese_confirm: "synthese_confirm",
    _back_home: "back_home",
  }
}
