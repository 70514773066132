import React from 'react';
import { View } from 'react-native';

import { FontIcon, Text, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { InputEditAmountWithLinkAndContribution } from '@ere-uilib/organisms';
import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const CompartmentCardHeader: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  backGroundColorCustom,
  dispositifTitle,
  compartmentTitle,
  fieldAmount,
  isContributionDisplayed,
  contributionPotentiel,
  contributionEstimation,
  children,
  editable,
  ...inputProps
}) => {
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumberWithPlusMinus } = useTranslation();
  const styles = useStyles(
    { theme, backGroundColorCustom },
    { containerStyle }
  );
  const isContributionPotentiel = (typeof contributionPotentiel === "number" && contributionPotentiel > 0)
  return (
    <View style={styles.containerStyle}>
      <View>
        <View style={styles.headerContainerStyle}>
          <View style={styles.compartmentContainerStyle}>
            <Title
              style={styles.compartmentTitleStyle}
              variant="t6">
              {dispositifTitle}
              {compartmentTitle && ` - ${compartmentTitle}`}
            </Title>
            <InputEditAmountWithLinkAndContribution
              {...inputProps}
              amount={fieldAmount}
              editable={editable}
              fieldAmount={fieldAmount}
              isContributionDisplayed={isContributionDisplayed}
              isContributionLast
            />

          </View>
          {!editable && isContributionDisplayed && isContributionPotentiel && (
            <View style={{ flexDirection: "row",     marginBottom: 10 }}>
              <View style={{ flex: 1 }}>
{
                  // JIRA-6282
                  /*
                  <Text
                    style={styles.contributionPotentielStyles}
                    weight="light">
                    {formatMessage({
                      id: 'PIChoixInvestInvestProjetAbondementPotentielLabel',
                    })}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <FontIcon
                      name={IconEnum.CONTRIBUTION}
                      size={theme.metrics.iconSizes.xxm}
                      style={styles.contributionIconLeft}
                    />
                    <Text
                      style={styles.contributionPotentielStyles}
                      weight="bold">
                      {formatCurrencyNumberWithPlusMinus({
                        value: contributionPotentiel ? contributionPotentiel : 0,
                      })}
                    </Text>
                  </View>
                   */
                }
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={styles.contributionEstimationStyles}
                  weight="light">
                  {formatMessage({
                    id: 'PIChoixInvestInvestProjetAbondementEstimeLabel',
                  })}
                </Text>
                <View
                  style={{ flexDirection: "row", alignSelf: "flex-end",
                    minWidth: 180 }}>
                  <FontIcon
                    name={IconEnum.CONTRIBUTION}
                    size={theme.metrics.iconSizes.xxm}
                    style={styles.contributionIconRight}
                  />
                  <Text
                    style={styles.contributionEstimationStyles}
                    weight="bold">
                    {formatCurrencyNumberWithPlusMinus({
                      value: contributionEstimation ? contributionEstimation : 0,
                    })}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {!editable && isContributionDisplayed && !isContributionPotentiel && (
            <View style={{ flexDirection: "row",     marginBottom: 10 }}>
              <Text
                style={styles.contributionPotentielStyles}
                weight="light">
                {formatMessage({
                  id: 'PIChoixInvestInvestProjetAbondementConsommeLabel',
                })}
              </Text>
            </View>
          )}
        </View>
      </View>

      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
