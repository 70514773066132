import { Platform } from 'react-native'

import { RouteNames } from '@constants/navigation'

interface GetNavigationPathConfigParams {
  isAuthenticated: boolean
  isCguAccepted: boolean
}

export const getNavigationPathConfig = ({
  isAuthenticated,
  isCguAccepted
}: GetNavigationPathConfigParams) => {
  return {
    screens: {
      [RouteNames.MaintenancePage]: '/maintenance-en-cours',
      [RouteNames.CguAcceptation]: '/cgu/accept',
      [RouteNames.OidcCallbackRedirect]: '/oidc/callback',
      [RouteNames.OidcCallbackRedirectSilentPage]: '/oidc/silent',
      [RouteNames.OidcLogoutCallbackRedirect]: '/oidc/logout',
      [RouteNames.NativeLoginPage]: '/oidc/login',
      [RouteNames.BiConsentment]: '/budget-insight/consentement',
      [RouteNames.BiErrorPage]: '/budget-insight/error',
      [RouteNames.BiConnect]: '/budget-insight/connect',
      [RouteNames.BiCallback]: '/budget-insight/redirection',
      [RouteNames.SelfcareLetterLanding]: '/selfcare',
      [RouteNames.Error]: '/erreur',
      [RouteNames.Logout]: '/deconnexion',
      [RouteNames.EndOidcSessionPage]: '/oidc/endsession',
      [RouteNames.EndOidcSessionCallbackPage]: '/oidc/logoutcallback',
      [RouteNames.CompanyChoicePage]: 'choix-entreprise',
      [RouteNames.Support]: '/support',
      [RouteNames.MobileMenuModal]: '/menu',
      [RouteNames.BackOfficeAccess]: '/back-office',
      [RouteNames.OperationTypeModalPage]: '/Operation',
      [RouteNames.VVPDetails]: '/details-versement-volontaire-programmee',
      [RouteNames.OperationDetails]: '/operation-details',
      [RouteNames.OperationCancel]: '/operation-annulation',
      [RouteNames.OperationCancelSuccess]: '/operation-annulation-succes',
      [RouteNames.DisponibilityTermPage]: '/aide-au-deblocage',
      [RouteNames.SapiendoRedirectPage]: '/ma-retraite',
      [RouteNames.RedirectOperationPage]: '/redirection',
      [RouteNames.Contact]: '/contact-preferences',
      [RouteNames.ConsentTransferCredit]: '/consentement-transfer-credit',
      [RouteNames.BusinessContact]: '/business-contact-preferences',
      [RouteNames.MaintenanceModal]: '/maintenance',
      ...(!isAuthenticated || !isCguAccepted
        ? { [RouteNames.LegalInfo]: '/compte/infos-legales' }
        : {}),
      [RouteNames.RelationshipEntringProcess]: {
        path: '/crea-compte',
        screens: {
          initialRouteName: RouteNames.Welcome,
          [RouteNames.Welcome]: '/bienvenue',
          [RouteNames.FConnectSignUp]: '/fc-callback',
          [RouteNames.ValidateSignup]: '/etapes',
          [RouteNames.ValidationSignupEmailCode]: '/validation-email',
          [RouteNames.ValidationSignupPhoneCode]: '/validation-telephone',
          [RouteNames.ValidateSignupThankYou]: '/identite-valide',
          [RouteNames.ChooseIdentificationMail]: '/choix-mail',
          [RouteNames.ChooseIdentificationPhone]: '/choix-telephone',
          [RouteNames.ChooseIdentificationEmailCodePage]: '/choix-mail-code',
          [RouteNames.ChooseIdentificationPhoneCodePage]: '/choix-telephone-code',
          [RouteNames.ChoosePassword]: '/choix-mdp',
          [RouteNames.AccountCreationSuccess]: '/succes',
          [RouteNames.AccountTerms]: '/cgu',
          [RouteNames.CompanyBenefits]: '/decouverte',
          [RouteNames.OnboardingPersoneo]: '/personeo',
          [RouteNames.OnboardingVisiogo]: '/visiogo'
        }
      },
      [RouteNames.ForgetPassword]: {
        path: '/mot-de-passe-oublie',
        screens: {
          initialRouteName: RouteNames.ForgetPasswordBegin,
          [RouteNames.ForgetPasswordBegin]: '/',
          [RouteNames.ForgetPasswordValidationCodePage]: '/verification-code',
          [RouteNames.ForgetPasswordChoosePasswordPage]: '/choix-mdp',
          [RouteNames.ForgetPasswordCongratsPage]: '/succes'
        }
      },
      [RouteNames.InstallmentStack]: {
        path: '/versements',
        screens: {
          initialRouteName: RouteNames.Installments,
          [RouteNames.Installments]: '/',
          [RouteNames.InstallmentsRepartition]: '/repartition',
          [RouteNames.InstallmentsSynthesis]: '/synthese',
          [RouteNames.InstallmentsRequirementDefiniton]: 'definition_besoin',
          [RouteNames.InstallmentPlanChoice]: 'choix-dispositif',
          [RouteNames.InstallmentsPaymentMode]: '/mode_de_payement',
          [RouteNames.InstallmentFailed]: '/echec',
          [RouteNames.InstallmentSuccess]: '/succes',
          [RouteNames.InstallmentLoading]: '/chargement',
          [RouteNames.InstallmentPaymentCBHtml]: '/paiement-cb',
          [RouteNames.InstallmentModal]: '/abandon'
        }
      },
      [RouteNames.BankDetailsStack]: {
        path: '/coordonnees-bancaires',
        screens: {
          initialRouteName: RouteNames.BankDetailsSummary,
          [RouteNames.BankSetDomiciliation]: '/domiciliation',
          [RouteNames.BankSetDocuments]: '/documents',
          [RouteNames.BankSetIban]: '/saisie',
          [RouteNames.BankCopyIban]: '/copie',
          [RouteNames.BankSignature]: '/signature',
          [RouteNames.BankSignatureRC]: '/signature-rc',
          [RouteNames.BankDetailsSummary]: '/accueil'
        }
      },
      [RouteNames.RefundsStack]: {
        path: '/remboursement',
        screens: {
          initialRouteName: RouteNames.RefundOnboarding,
          [RouteNames.RefundOnboarding]: '/',
          [RouteNames.RefundsRequirementDefiniton]: 'definition_besoin',
          [RouteNames.UnavailableRefundRC]: 'indisponible_rc',
          [RouteNames.RefundPlanChoice]: 'choix-dispositif',
          [RouteNames.RefundRepartition]: 'repartition',
          [RouteNames.RefundAskProof]: 'justificatifs',
          [RouteNames.RefundModal]: '/abandon',
          [RouteNames.RefundsRecap]: 'synthese',
          [RouteNames.RefundSuccess]: 'succes',
          [RouteNames.RefundFailed]: 'echec'
        }
      },
      [RouteNames.ArbitrationStack]: {
        path: '/arbitrage',
        screens: {
          initialRouteName: RouteNames.ArbitrationChoice,
          [RouteNames.ArbitrationChoice]: '/',
          [RouteNames.ArbitrationOnboarding]: '/etapes',
          [RouteNames.ArbitrationOADRepartition]: '/oad-repartition',
          [RouteNames.ArbitrationPlanChoice]: 'choix-dispositif',
          [RouteNames.ArbitrationDisinvestmentsSaving]: 'desinvestissement',
          [RouteNames.ArbitrationSynthesis]: '/synthese',
          [RouteNames.ArbitrationReinvestment]: 'reinvestissement',
          [RouteNames.ArbitrationFailure]: 'echec',
          [RouteNames.ArbitrationSuccess]: 'succes'
        }
      },
      [RouteNames.BottomTabNavigator]: {
        screens: {
          initialRouteName: RouteNames.Home,
          [RouteNames.Home]: '/accueil',
          [RouteNames.SavingsStack]: {
            path: '/epargne',
            screens: {
              initialRouteName: RouteNames.Savings,
              [RouteNames.Savings]: '',
              [RouteNames.VVPList]: '/liste_VVP',
              [RouteNames.funds]: '/fonds'
            }
          },
          [RouteNames.HistoryStack]: {
            path: '/historique',
            screens: {
              initialRouteName: RouteNames.History,
              [RouteNames.History]: ''
            }
          },
          [RouteNames.ProfileStack]: {
            path: '/compte',
            screens: {
              initialRouteName: RouteNames.Profile,
              [RouteNames.ProfileEditEmail]: '/modification-mail',
              [RouteNames.ProfileEditPhone]: '/modification-telephone',
              [RouteNames.Profile]: '/profil',
              [RouteNames.EditProfileInformations]: '/edition-informations',
              [RouteNames.EditPassword]: '/edition-mdp',
              [RouteNames.ContactPreferences]: '/contact-preferences',
              [RouteNames.BusinessContactPreferences]: '/business-contact-preferences',
              [RouteNames.ConnexionBiometrique]: '/connexion-biometrique',
              ...(isAuthenticated && isCguAccepted
                ? { [RouteNames.ProfileLegalInfo]: '/infos-legales' }
                : {}),
              ...(isAuthenticated && isCguAccepted
                ? { [RouteNames.Editorial]: '/editoriales' }
                : {})
            }
          },
          [RouteNames.MobileMenuModal]: '/modal'
        }
      },
      ...(['ios', 'android'].includes(Platform.OS) && __DEV__
        ? { [RouteNames.Storybook]: '/storybook' }
        : {}),
      [RouteNames.OtpValidation]: {
        screens: {
          initialRouteName: RouteNames.OtpValidationStart,
          [RouteNames.OtpValidationStart]: '/validation-otp',
          [RouteNames.OtpValidationSuccess]: '/validation-succes',
          [RouteNames.OtpValidationForm]: '/validation-otp-formulaire'
        }
      },
      [RouteNames.SelfcareStack]: {
        path: '/activer-compte',
        screens: {
          initialRouteName: RouteNames.ActivateAccountModeSelection,
          [RouteNames.ActivateAccountModeSelection]: '/',
          [RouteNames.PersoneoIdentifierForm]: '/personeo',
          [RouteNames.VisiogoIdentifierForm]: '/visiogo',
          [RouteNames.LetterIdentifierForm]: '/courrier',
          [RouteNames.SelfcarePersoneoSuccess]: '/personeo-succes',
          [RouteNames.SelfcareVisiogoSuccess]: '/visiogo-succes',
          [RouteNames.SelfcareLetterSuccess]: '/courrier-succes'
        }
      },
      [RouteNames.ProfitSharingIncentive]: {
        path: '/participation-interessement',
        screens: {
          initialRouteName: RouteNames.ProfitSharingIncentiveDescription,
          [RouteNames.ProfitSharingIncentiveDescription]: '/description',
          [RouteNames.ProfitSharingIncentiveAssigment]: '/affectation',
          [RouteNames.ProfitSharingIncentiveOnboarding]: '/etapes',
          [RouteNames.ProfitSharingIncentiveRepartition]: '/repartition',
          [RouteNames.ProfitSharingIncentiveSynthesis]: '/synthese',
          [RouteNames.ProfitSharingIncentiveSuccess]: '/succes',
          [RouteNames.ProfitSharingIncentiveDefaultAffectations]: '/affectations-par-defaut',
          [RouteNames.ProfitSharingIncentiveModal]: '/abandon',
          [RouteNames.ProfitSharingIncentiveFailedPage]: '/echec'
        }
      },
      [RouteNames.DocumentSpaceStack]: {
        path: '/documents',
        screens: {
          [RouteNames.DocumentSpaceHome]: '/',
          [RouteNames.DocumentSpaceEDocumentsFiles]: '/edocuments',
          [RouteNames.DocumentSpaceEDocumentsSubscription]: '/edocuments/abonnement',
          [RouteNames.DocumentSpaceGeneralDocuments]: '/documents-generaux',
          [RouteNames.DocumentSpaceGeneralDocumentsFiles]: '/documents-generaux/fichiers',
          [RouteNames.DocumentSpaceViewer]: '/apercu'
        }
      },
      [RouteNames.AnnuityStack]: {
        path: '/ma-rente',
        screens: {
          initialRouteName: RouteNames.AnnuityHomePage,
          [RouteNames.AnnuityHomePage]: '/',
          [RouteNames.AnnuitySimulator]: '/simulateur'
        }
      },
      [RouteNames.RetirementStack]: {
        path: '/depart-retraite',
        screens: {
          initialRouteName: RouteNames.OnboardingPage,
          [RouteNames.OnboardingPage]: '/',
          [RouteNames.DetailsPage]: '/details',
          [RouteNames.RetirementPage]: '/retraite',
          [RouteNames.RetirementRecoverSavingPage]: '/recuperer-epargne',
          [RouteNames.RetirementModal]: '/abandon'
        }
      },
      [RouteNames.ContributionModal]: '/mon-abondement',
      [RouteNames.ControlData]: '/compte/donnees-pilotage',
      [RouteNames.VotAccess]: '/votAccess',
      NotFound: '*'
    }
  }
}
