import axios, { AxiosResponse } from 'axios';

import { API_URL } from '@config/index';
import { OtpChanelTypeEnum } from '@constants/index';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { ContactPreferencesOptin } from '@modules/profile/actions/profileActions';
import { AddressTypeDtoResponse, ProfileState, UserProfilDtoResponse } from '@modules/profile/types';

export async function fetchConsents(): Promise<ProfileState> {
  return axios.get(`${API_URL}/api/v1/consents`);
}

export async function fetchEditorialContent(code: string, companyId: string,): Promise<any> {
  return axios.get(`${API_URL}/api/v1/application/companies/${companyId}/pageContent/${code}`);
}

export async function acceptConsents(code: string): Promise<ProfileState> {
  return axios.post(`${API_URL}/api/v1/consents/accept`, { code: code });
}

export async function fetchProfileMenu(companyId: string): Promise<ProfileState> {
  return axios.get(`${API_URL}/api/v1/application/menus/profilemenu/${companyId}`);
}

export async function fetchProfileUserInfos(): Promise<AxiosResponse<UserProfilDtoResponse>> {
  return axios.get(`${API_URL}/api/v1/Profile`);
}
export const fetchProfileAddressInfos = async (companyId: string): Promise<AxiosResponse<AddressTypeDtoResponse[]>> => axios.get(`${API_URL}/api/v1/companies/${companyId}/profile/addresses`)

interface FetchOtpInitVerifyEmailChannel {
  email?: string;
  demandId?: string;
}
export interface FetchOtpFlowInitializeChanel {
  data: {
    requestId: string;
    email?: string;
    phoneNumber?: string;
    newPhoneNumber?: string;
    newEmail?: string;
  };
}

export async function fetchOtpInitVerifyEmailChannel({
  email,
  demandId,
}: FetchOtpInitVerifyEmailChannel): Promise<FetchOtpFlowInitializeChanel> {
  return demandId
    ? axios.get(API_URL + '/api/v1/demands/mailupdates/' + demandId)
    : axios.post(`${API_URL}/api/v1/demands/mailupdates/init`, {
      newEmail: email,
    });
}

export async function fetchEditPasswordRequest(
  currentPassword: string,
  newPassword: string
): Promise<ProfileState> {
  return axios.patch(`${API_URL}/api/v1/account/updatepassword`, {
    currentPassword: currentPassword,
    newPassword: newPassword,
  });
}

export function fetchVerifMailUpdateIsEnable() {
  return axios.get(`${API_URL}/api/v1/demands/mailupdates/authorize`);
}
export function fetchVerifPhoneUpdateIsEnable() {
  return axios.get(`${API_URL}/api/v1/demands/phoneupdates/authorize`);
}

export async function fetchChangeProfileUserPhone({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<ProfileState> {
  return axios.post(`${API_URL}/api/v1/account/???/init`, {
    phoneNumber,
  });
}

interface FetchOtpInitVerifySmsChannelType {
  demandId?: string
  newPhoneNumber?: string
}
export function fetchOtpInitVerifySmsChannel({
  demandId,
  newPhoneNumber,
}: FetchOtpInitVerifySmsChannelType) {
  if (demandId) {
    return axios.get(API_URL + '/api/v1/demands/phoneupdates/' + demandId);
  }
  return axios.post(API_URL + '/api/v1/demands/phoneupdates/init', {
    newMobilePhone: newPhoneNumber,
  });
}

export interface FetchOtpFlowInitializeChanel {
  data: {
    requestId: string;
    email?: string;
    phoneNumber?: string;
    newPhoneNumber?: string;
    newEmail?: string;
  };
}

export async function fetchOtpFlowInitializeChanel({
  requestType,
  chanelType,
  channelValue,
  demandId,
}: {
  requestType?: OtpRequestTypeEnum;
  chanelType?: OtpChanelTypeEnum;
  channelValue?: string;
  demandId?: string;
}): Promise<FetchOtpFlowInitializeChanel | undefined> {
  const isMail = chanelType === OtpChanelTypeEnum.MAIL;
  const isSms = chanelType === OtpChanelTypeEnum.SMS;
  const isMailDocaposte = chanelType === OtpChanelTypeEnum.MAIL_DOCAPOSTE;
  const isSmsDocaposte = chanelType === OtpChanelTypeEnum.SMS_DOCAPOSTE;

  if (requestType === OtpRequestTypeEnum.VERIFY) {
    if (isMail) {
      return fetchOtpInitVerifyEmailChannel({ email: channelValue, demandId });
    }

    if (isSms) {
      return fetchOtpInitVerifySmsChannel({ demandId });
    }
  }

  if (requestType === OtpRequestTypeEnum.SECURE) {
    let backChannelType: OtpChanelTypeEnum = OtpChanelTypeEnum.UNKNOWN;

    if (isMail) {
      backChannelType = OtpChanelTypeEnum.MAIL;
    } else if (isSms) {
      backChannelType = OtpChanelTypeEnum.SMS;
    } else if (isMailDocaposte) {
      backChannelType = OtpChanelTypeEnum.MAIL_DOCAPOSTE;
    } else if (isSmsDocaposte) {
      backChannelType = OtpChanelTypeEnum.SMS_DOCAPOSTE;
    }

    return axios.post(`${API_URL}/api/v1/Authentication/init`, {
      type: backChannelType,
    });
  }

  return new Promise((_, reject) => reject('ERROR'));
}

export async function fetchOtpFlowAskCode({
  requestType,
  otpIdentifier,
  chanelType,
}: {
  requestType: OtpRequestTypeEnum | undefined;
  otpIdentifier: string | undefined;
  chanelType: OtpChanelTypeEnum | undefined;
}): Promise<ProfileState> {
  if (requestType === OtpRequestTypeEnum.VERIFY) {
    if (chanelType === OtpChanelTypeEnum.MAIL) {
      return axios.post(`${API_URL}/api/v1/demands/mailupdates/check`, {
        demandId: otpIdentifier,
      });
    }

    if (chanelType === OtpChanelTypeEnum.SMS) {
      return axios.post(`${API_URL}/api/v1/demands/phoneupdates/check`, {
        demandId: otpIdentifier,
      });
    }
  }

  if (requestType === OtpRequestTypeEnum.SECURE) {
    return axios.post(`${API_URL}/api/v1/Authentication/Send`, {
      requestId: otpIdentifier,
    });
  }

  return new Promise((_, reject) => reject('ERROR'));
}

export async function fetchOtpFlowSendCode({
  requestType,
  otpIdentifier,
  chanelType,
  otpCode,
}: {
  requestType: OtpRequestTypeEnum | undefined;
  otpIdentifier: string | undefined;
  chanelType: OtpChanelTypeEnum | undefined;
  otpCode: string | undefined;
}): Promise<ProfileState> {
  if (requestType === OtpRequestTypeEnum.VERIFY) {
    if (chanelType === OtpChanelTypeEnum.MAIL) {
      return axios.post(`${API_URL}/api/v1/demands/mailupdates/confirm`, {
        demandId: otpIdentifier,
        otpCode,
      });
    }

    if (chanelType === OtpChanelTypeEnum.SMS) {
      return axios.post(`${API_URL}/api/v1/demands/phoneupdates/confirm`, {
        demandId: otpIdentifier,
        otpCode,
      });
    }
  }

  if (requestType === OtpRequestTypeEnum.SECURE) {
    return axios.post(`${API_URL}/api/v1/Authentication/Validate`, {
      requestId: otpIdentifier,
      code: otpCode,
    });
  }

  return new Promise((_, reject) => reject('ERROR'));
}

interface FetchUpdateContactPreferences {
  demandId: string | null
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}
export function fetchUpdateContactPreferences({
  demandId,
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
}: FetchUpdateContactPreferences) {
  return axios.post(`${API_URL}/api/v1/demands/user-preferences`, {
    demandId,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
  });
}

export function fetchGetContactPreferences() {
  return axios.get(API_URL + '/api/v1/user-preferences');
}

// optin

export function fetchGetContactPreferencesOptin() {
  return axios.get(API_URL + '/api/v1/optin');
}
export function fetchSetContactPreferencesOptin(optinPayload: ContactPreferencesOptin) {
  return axios.post(API_URL + '/api/v1/demands/optin', optinPayload);
}

// bankData
export function fetchGetBankData(companyId: string) {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/bankaccountdetails`);
}

interface FetchUpdateUserPhoneInit {
  newPhoneNumber: string;
  isOtpCodeGenerated: boolean;
  isTokenConfirmed: boolean;
  demandId: string;
  created: string;
  demandType: string;
}
export async function fetchChangeProfileUserPhoneInit(
  newPhoneNumber: string,
  sendType: string
): Promise<FetchUpdateUserPhoneInit> {
  return axios.post(`${API_URL}/api/v1/account/phoneupdates/init`, {
    newMobilePhone: newPhoneNumber,
    sendType: sendType
  });
}

export async function fetchChangeProfileUserPhoneCheck(
  demandId: string,
  sendType: string
): Promise<boolean> {
  return axios.post(`${API_URL}/api/v1/account/phoneupdates/check`, {
    demandId: demandId,
    sendType: sendType
  });
}

export async function fetchChangeProfileUserPhoneConfirm(
  demandId: string,
  otpCode: string
): Promise<boolean> {
  return axios.post(`${API_URL}/api/v1/account/phoneupdates/confirm`, {
    demandId: demandId,
    otpCode: otpCode
  });
}
