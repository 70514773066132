import {
  innerNavigations, OPERAIONS_HISTORY_TYPES_ROULE,
  OPERATION_HISTORY_RANGE_DATE_ROULE,
  REPARTITION_SORT_FILTER_LIST
} from '@constants/index';
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { AccountsAction, DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { SavingsAction, SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';

import { SavingsState } from './types';
import {
  concatObject,
  concatSavingHistoryObject,
  filterOperationsHistory,
  getItemsfromfiltredArray,
  groupRepartitionsBySupportName
} from './utils';
import { InstallmentAction } from '@modules/installment/actions/installmentActionsTypes';
import {mockOperationDetailsCapitalizedDividend} from "@__mocks__/mockOperationDetails";

export const initialState: SavingsState = {
  onGoingVVP: [],
  onGoingVVPDeleteModalVisible: false,
  repartitions: [],
  unGroupedRepartitions: [],
  repartitionFilters: {
    sortFilter: REPARTITION_SORT_FILTER_LIST[0].value,
    groupFilter: [],
  },
  availabilitiesDetail: [],
  availabilitiesFunds: [],
  accountRepartitions: [],
  operationsHistory: [],
  installments: [],
  operationsHistoryFilterRoules: {
    DateRange: OPERATION_HISTORY_RANGE_DATE_ROULE,
    TypesRoule: OPERAIONS_HISTORY_TYPES_ROULE.sort((a, b) =>
      a.label.localeCompare(b.label)
    ),
  },
  selectedPlan: 0,
  selectedTab: innerNavigations[0],
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
  savingsHistory: [],
  savingsHistoryFilters: {
    period: 1,
  },
  isDetailsVVPPopInOpened: false,
  operationDetails: undefined,
};

export default function SavingsReducer(
  state: SavingsState = initialState,
  action:
    | SavingsAction
    | AuthenticateAction
    | AccountsAction
    | InstallmentAction,
): SavingsState {
  switch (action?.type) {
  case SavingsActionsType.GET_INSTALLMENTS_SUCCESS:
    return {
      ...state,
      installments: action.installments,
    };
  case SavingsActionsType.SET_IS_DETAILS_VVP_POPIN:
    return {
      ...state,
      isDetailsVVPPopInOpened: action.isOpened,
    };
  case SavingsActionsType.GET_REPARTITIONS_SUCCESS:
    return {
      ...state,
      accountRepartitions: action.repartitions,
      ...{
        unGroupedRepartitions: concatObject(
          state.repartitions,
          'Account',
          action.repartitions
        ),
        repartitions: concatObject(
          state.repartitions,
          'Account',
          groupRepartitionsBySupportName(action.repartitions)
        ),
      },
    };
  case SavingsActionsType.GET_REPARTITIONS_BY_PLAN_SUCCESS:
    return {
      ...state,
      ...{
        unGroupedRepartitions: concatObject(
          state.repartitions,
          action.planId,
          action.repartitions
        ),
        repartitions: concatObject(
          state.repartitions,
          action.planId,
          groupRepartitionsBySupportName(action.repartitions),
        ),
      },
    };
  case SavingsActionsType.SET_REPARTITION_FILTER:
    return {
      ...state,
      repartitionFilters: action.repartitionFilters,
    };
  case SavingsActionsType.GET_OPERATIONS_HISTORY_SUCCESS:
    return {
      ...state,
      ...{
        operationsHistory: concatObject(
          state.operationsHistory,
          'Account',
          filterOperationsHistory(
            getItemsfromfiltredArray(
              state.operationsHistory,
              'Account',
              action.operationsHistory
            ),
            state
          )
        ),
      },
    };
  case SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_SUCCESS:
    return {
      ...state,
      ...{
        operationsHistory: concatObject(
          state.operationsHistory,
          action.planId,
          filterOperationsHistory(
            getItemsfromfiltredArray(
              state.operationsHistory,
              action.planId,
              action.operationsHistory
            ),
            state
          )
        ),
      },
    };
  case SavingsActionsType.GET_OPERATION_DETAILS_SUCCESS:
    return {
      ...state,
      operationDetails: action.operationDetails,
    };
  case SavingsActionsType.GET_AVAILABILITIES_DETAILS_SUCCESS:
    return {
      ...state,
      availabilitiesDetail: concatObject(
        state.availabilitiesDetail,
        'Account',
        action.availabilitiesDetail
      ),
    };
  case SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_SUCCESS:
    return {
      ...state,
      ...{
        availabilitiesDetail: concatObject(
          state.availabilitiesDetail,
          action.planId,
          action.availabilitiesDetail
        ),
      },
    };
  case SavingsActionsType.GET_AVAILABILITIES_FUNDS_SUCCESS:
    return {
      ...state,
      availabilitiesFunds: concatObject(
        state.availabilitiesFunds,
        'Account',
        action.availabilitiesFunds
      ),
    };
  case SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_SUCCESS:
    return {
      ...state,
      ...{
        availabilitiesFunds: concatObject(
          state.availabilitiesFunds,
          action.planId,
          action.availabilitiesFunds
        ),
      },
    };
  case SavingsActionsType.APPLY_OPERATIONS_HISTORY_FILTER_REQUEST:
    return {
      ...state,
      operationsHistoryFilterRoules: action.filterRoules,
      operationsHistory: initialState.operationsHistory,
    };
  case SavingsActionsType.GET_SELECTED_PLAN_AND_NAVIGATION:
    return {
      ...state,
      selectedTab: action.selectedTab,
      selectedPlan: action.selectedPlan,
    };
  case SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_SUCCESS:
    return {
      ...state,
      savingsHistory: concatSavingHistoryObject(
        state.savingsHistory,
        action.planId,
        state.savingsHistoryFilters.period,
        action.savingsHistory
      ),
    };
  case SavingsActionsType.GET_SAVINGS_HISTORY_SUCCESS:
    return {
      ...state,
      savingsHistory: concatSavingHistoryObject(
        state.savingsHistory,
        'Account',
        state.savingsHistoryFilters.period,
        action.savingsHistory,
      ),
    };

  case SavingsActionsType.APPLY_SAVINGS_HISTORY_FILTER_REQUEST:
    return {
      ...state,
      savingsHistoryFilters: action.savingsHistoryFilters,
    };
  case SavingsActionsType.GET_ONGOING_VVP_HISTORY_SUCCESS:
    return {
      ...state,
      onGoingVVP: action.onGoingVVP,
    };
  case SavingsActionsType.SET_ONGOING_VVP_DELETE_MODAL_VISIBLE_STATE:
    return {
      ...state,
      onGoingVVPDeleteModalVisible: action.visible,
    };
  case SavingsActionsType.INIT_SAVINGS_LISTINGS:
    return {
      ...state,
      onGoingVVP: initialState.onGoingVVP,
      onGoingVVPDeleteModalVisible: initialState.onGoingVVPDeleteModalVisible,
      repartitions: initialState.repartitions,
      unGroupedRepartitions: initialState.unGroupedRepartitions,
      repartitionFilters: initialState.repartitionFilters,
      availabilitiesDetail: initialState.availabilitiesDetail,
      accountRepartitions: initialState.accountRepartitions,
      operationsHistory: initialState.operationsHistory,
      installments: initialState.installments,
      operationsHistoryFilterRoules: initialState.operationsHistoryFilterRoules,
      selectedPlan: initialState.selectedPlan,
      selectedTab: initialState.selectedTab,
      error: initialState.error,
      savingsHistory: initialState.savingsHistory,
      savingsHistoryFilters: initialState.savingsHistoryFilters,
      isDetailsVVPPopInOpened: initialState.isDetailsVVPPopInOpened,
    };
  case ErrorActionsType.INIT_ALL_ERRORS:
    return {
      ...state,
      error: initialState.error,
    };
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
  case DashboardActionsType.PUT_SELECTED_COMPANY_SUCCESS:
  case SavingsActionsType.INIT_SAVINGS_REDUCER:
    return initialState;
  default:
    return state;
  }
}
