import {API_URL} from '@config/index';
import {OnGoingVVP, SavingsState} from '@modules/savings/types';
import axios from 'axios';


export async function fetchRepartitions(
  companyId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/repartitions`
  );
}

export async function fetchRepartitionsByPlan(
  companyId: string,
  planId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/repartitions`
  );
}
export async function fetchOperationsHistoryByPlan(
  companyId: string,
  planId: string,
  year: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/operations/${year}`
  );
}

export async function fetchOperationsHistory(
  companyId: string,
  year: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/operations/${year}`
  );
}

export async function fetchAvailabilitiesDetail(
  companyId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/availabilities/details`
  );
}

export async function fetchAvailabilitiesDetailByPlan(
  companyId: string,
  planId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/availabilities/details`
  );
}

export async function fetchAvailabilitiesFunds(
  companyId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/availabilities/funds`
  );
}

export async function fetchAvailabilitiesFundsByPlan(
  companyId: string,
  planId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/availabilities/funds`
  );
}

export async function fetchInstallments(
  companyId: string,
  planId: string,
  year: string
): Promise<any> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}${
      planId ? '/plans/' + planId : ''
    }/operations/${year}/consolided`
  );
}

export async function fetchSavingsHistoryByPlan(
  companyId: string,
  planId: string,
  period: number
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/savingHistory/${period}`
  );
}

export async function fetchSavingsHistory(
  companyId: string,
  period: number
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/savingHistory/${period}`
  );
}

interface FetchOnGoingVVPHistory {
  data: OnGoingVVP[]
}
export async function fetchOnGoingVVPHistory(
  companyId: string,
  selectedPlanId?: number,
): Promise<FetchOnGoingVVPHistory> {
  if (selectedPlanId) {
    return axios.get(
      `${API_URL}/api/v1/companies/${companyId}/plans/${selectedPlanId}/operations/plannedPayments`
    );
  }

  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/operations/plannedPayments`
  );
}

export async function fetchOperationDetails(
  companyId: string,
  operationId: string
): Promise<SavingsState> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/operations/detail/${operationId}`
  );
}

export async function fetchOperationCancel(
  operationId: string
): Promise<SavingsState> {
  return axios.post(
    `${API_URL}/api/v1/demands/operations/${operationId}/cancel`
  );
}