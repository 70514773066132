import {
  OtpChanelTypeEnum, UserInfoTypeEnum
} from '@constants/index';

import {
  AddressTypeState,
  ConsentsState,
  EditorialContentState,
  OtpErrorState,
  OtpParametersState,
  ProfileErrorState,
  ProfileMenuState,
  ProfileUserInfosState
} from '../types';
import * as actions from './profileActionsTypes';
import { ProfileActionsType } from './profileActionsTypes';

// biometrics
export function SetBiometricLockingUserFromProfileMenu(isLocked: boolean): actions.SetBiometricLockingUserFromProfileMenuAction {
  return {
    type: ProfileActionsType.LOCK_BIOMETRIC_USER_FROM_PROFILE_MENU,
    isLocked
  };
}
// Get consents
export function getConsentsRequest(): actions.GetConsentsRequestAction {
  return {
    type: ProfileActionsType.GET_CONSENTS_REQUEST,
  };
}

export function getEditorialContentRequest(
  code: string,
): actions.GetEditorialContentRequestAction {
  return {
    type: ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST,
    code,
  };
}

export function getEditorialContentSuccess(
  content: EditorialContentState,
): actions.GetEditorialContentSuccessAction {
  return {
    type: ProfileActionsType.GET_EDITORIAL_CONTENT_SUCCESS,
    content,
  };
}

export function getEditorialContentFailure(
  error: ProfileErrorState,
): actions.GetEditorialContentFailureAction {
  return {
    type: ProfileActionsType.GET_EDITORIAL_CONTENT_FAILURE,
    error,
  };
}

export function getConsentsSuccess(
  consents: ConsentsState[],
): actions.GetConsentsSuccessAction {
  return {
    type: ProfileActionsType.GET_CONSENTS_SUCCESS,
    consents,
  };
}

export function getConsentsFailure(
  error: ProfileErrorState,
): actions.GetConsentsFailureAction {
  return {
    type: ProfileActionsType.GET_CONSENTS_FAILURE,
    error,
  };
}

//Accept consents

export function acceptConsentsRequest(
  code: string,
): actions.AcceptConsentsRequestAction {
  return {
    type: ProfileActionsType.ACCEPT_CONSENTS_REQUEST,
    code,
  };
}

export function acceptConsentsSuccess(
  code: string,
  isAccepted: boolean
): actions.AcceptConsentsSuccessAction {
  return {
    type: ProfileActionsType.ACCEPT_CONSENTS_SUCCESS,
    code,
    isAccepted,
  };
}

export function acceptConsentsFailure(
  error: ProfileErrorState,
): actions.AcceptConsentsFailureAction {
  return {
    type: ProfileActionsType.ACCEPT_CONSENTS_FAILURE,
    error,
  };
}

// Get profileMenu

export function getProfileMenuRequest(): actions.GetProfileMenuRequestAction {
  return {
    type: ProfileActionsType.GET_PROFILE_MENU_REQUEST,
  };
}

export function getProfileMenuSuccess(
  profileMenu: ProfileMenuState,
): actions.GetProfileMenuSuccessAction {
  return {
    type: ProfileActionsType.GET_PROFILE_MENU_SUCCESS,
    profileMenu,
  };
}

export function getProfileMenuFailure(
  error: ProfileErrorState,
): actions.GetProfileMenuFailureAction {
  return {
    type: ProfileActionsType.GET_PROFILE_MENU_FAILURE,
    error,
  };
}

// Get profileUserInfos

export function getProfileUserInfosRequest(): actions.GetProfileUserInfosRequestAction {
  return {
    type: ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST,
  };
}

export function getProfileAddressRequest(): actions.getProfileAddressRequestAction {
  return {
    type: ProfileActionsType.GET_PROFILE_ADDRESS_REQUEST,
  };
}

export function getProfileUserInfosSuccess(
  userInfos: ProfileUserInfosState,
): actions.GetProfileUserInfosSuccessAction {
  return {
    type: ProfileActionsType.GET_PROFILE_USER_INFOS_SUCCESS,
    userInfos,
  };
}

export const getProfilAddresssSuccess = (addressInfos: { addresses: AddressTypeState[] | null }): actions.GetProfilAddressSucessAction => {
  return {
    type: ProfileActionsType.GET_PROFILE_ADDRESS_SUCCESS,
    addressInfos
  };
}

export function getProfileUserInfosFailure(
  error: ProfileErrorState,
): actions.GetProfileUserInfosFailureAction {
  return {
    type: ProfileActionsType.GET_PROFILE_USER_INFOS_FAILURE,
    error,
  };
}

// Send user info change

export function sendProfileUserInfoChangeRequest(
  userInfoType: UserInfoTypeEnum,
  value: string
): actions.SendProfileUserInfoChangeRequestAction {
  return {
    type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_REQUEST,
    userInfoType,
    value,
  };
}

export function sendProfileUserInfoChangeSuccess(): actions.SendProfileUserInfoChangeSuccessAction {
  return {
    type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_SUCCESS,
  };
}

export function sendProfileUserInfoChangeFailure(
  error: ProfileErrorState,
): actions.SendProfileUserInfoChangeFailureAction {
  return {
    type: ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_FAILURE,
    error,
  };
}

// Validate user info change

export function validateProfileUserInfoChangeRequest(): actions.ValidateProfileUserInfoChangeRequestAction {
  return {
    type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_REQUEST,
  };
}

export function validateProfileUserInfoChangeSuccess(): actions.ValidateProfileUserInfoChangeSuccessAction {
  return {
    type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_SUCCESS,
  };
}

export function validateProfileUserInfoChangeFailure(
  error: ProfileErrorState,
): actions.ValidateProfileUserInfoChangeFailureAction {
  return {
    type: ProfileActionsType.VALIDATE_PROFILE_USER_INFO_CHANGE_FAILURE,
    error,
  };
}

// profil edit password
export function profilEditPasswordReset(): actions.EditPasswordResetAction {
  return {
    type: ProfileActionsType.EDIT_PASSWORD_RESET,
  };
}
export function profilEditPasswordRequest(
  currentPassword: string, newPassword: string
): actions.EditPasswordRequestAction {
  return {
    type: ProfileActionsType.EDIT_PASSWORD_REQUEST,
    currentPassword,
    newPassword,
  };
}

export function profilEditPasswordSuccess(): actions.EditPasswordSuccessAction {
  return {
    type: ProfileActionsType.EDIT_PASSWORD_SUCCESS,
  };
}

export function profilEditPasswordFailure(
  error: ProfileErrorState,
): actions.EditPasswordFailureAction {
  return {
    type: ProfileActionsType.EDIT_PASSWORD_FAILURE,
    error,
  };
}

// otp management
// start otp flow
export function otpFlowStartRequest(
  otpParameters: OtpParametersState
): actions.OtpFlowStartRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_START_REQUEST,
    otpParameters,
  };
}

export function otpFlowStartSuccess(): actions.OtpFlowStartSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_START_SUCCESS,
  };
}

export function otpFlowStartFailure(
  error: OtpErrorState
): actions.OtpFlowStartFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_START_FAILURE,
    error,
  };
}

export function askNavigationToUrl(
  url: string
): actions.AskNavigationToUrlAction {
  return {
    type: ProfileActionsType.ASK_NAVIGATION_TO_URL,
    url,
  };
}

// otp flow initialize chanel
export function otpFlowInitializeChanelRequest(
  chanelType: OtpChanelTypeEnum
): actions.OtpFlowInitializeChanelRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_REQUEST,
    chanelType,
  };
}

export function otpFlowInitializeChanelSuccess(): actions.OtpFlowInitializeChanelSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_SUCCESS,
  };
}

export function otpFlowInitializeChanelFailure(
  error: OtpErrorState
): actions.OtpFlowInitializeChanelFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_INITIALIZE_CHANEL_FAILURE,
    error,
  };
}

// otp flow ask code
export function otpFlowAskCodeRequest(
  chanelType?: OtpChanelTypeEnum
): actions.OtpFlowAskCodeRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_REQUEST,
    chanelType,
  };
}

export function otpFlowAskCodeSuccess(): actions.OtpFlowAskCodeSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_SUCCESS,
  };
}

export function otpFlowAskCodeFailure(
  error: OtpErrorState
): actions.OtpFlowAskCodeFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_FAILURE,
    error,
  };
}

// otp flow ask code again
export function otpFlowAskCodeAgainRequest(): actions.OtpFlowAskCodeAgainRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_REQUEST,
  };
}

export function otpFlowAskCodeAgainSuccess(): actions.OtpFlowAskCodeAgainSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_SUCCESS,
  };
}

export function otpFlowAskCodeAgainFailure(
  error: OtpErrorState
): actions.OtpFlowAskCodeAgainFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_FAILURE,
    error,
  };
}

// otp flow send code
export function otpFlowSendCodeRequest(
  otpCode: string
): actions.OtpFlowSendCodeRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_SEND_CODE_REQUEST,
    otpCode,
  };
}

export function otpFlowSendCodeSuccess(): actions.OtpFlowSendCodeSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_SEND_CODE_SUCCESS,
  };
}

export function otpFlowSendCodeFailure(
  error: OtpErrorState
): actions.OtpFlowSendCodeFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_SEND_CODE_FAILURE,
    error,
  };
}

// otp flow succes validation
export function otpFlowCompletedValidationRequest(): actions.OtpFlowCompletedValidationRequestAction {
  return {
    type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_REQUEST,
  };
}

export function otpFlowCompletedValidationSuccess(): actions.OtpFlowCompletedValidationSuccessAction {
  return {
    type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_SUCCESS,
  };
}

export function otpFlowCompletedValidationFailure(
  error: OtpErrorState
): actions.OtpFlowCompletedValidationFailureAction {
  return {
    type: ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_FAILURE,
    error,
  };
}

// update otp parameters
export function updateProfileOtpParameters(
  otpParameters: OtpParametersState
): actions.UpdateProfileOtpParametersAction {
  return {
    type: ProfileActionsType.UPDATE_PROFILE_OTP_PARAMETERS,
    otpParameters,
  };
}
// reset otp parameters
export function resetProfileOtpParameters(): actions.ResetProfileOtpParametersAction {
  return {
    type: ProfileActionsType.RESET_PROFILE_OTP_PARAMETERS,
  };
}
// reset otp flow
export function resetProfileOtpFlow(): actions.ResetProfileOtpFlowAction {
  return {
    type: ProfileActionsType.RESET_PROFILE_OTP_FLOW,
  };
}

// reset otp errors
export function initProfileOtpError(): actions.InitProfileOtpErrorAction {
  return {
    type: ProfileActionsType.INIT_PROFILE_OTP_ERROR,
  };
}

// Init Error
export function profileInitError(): actions.ProfileInitErrorAction {
  return {
    type: ProfileActionsType.PROFILE_INIT_ERROR,
  };
}

export interface ContactPreferencesRequest {
  hasNotificationsActivated: boolean,
  emailOptIn: boolean,
  smsOptIn: boolean,
  toDoPostSuccessActionType?: string
  demandId?: string | null
}
// otp flow succes validation
export function updateContactPreferencesRequest({
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
  toDoPostSuccessActionType,
  demandId,
}: ContactPreferencesRequest): actions.UpdateContactPreferenceRequestAction {
  return {
    type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_REQUEST,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
    toDoPostSuccessActionType,
    demandId,
  };
}

interface UpdateContactPreferencesSuccess {
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}
export function updateContactPreferencesSuccess({
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
}: UpdateContactPreferencesSuccess): actions.UpdateContactPreferenceSuccessAction {
  return {
    type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
  };
}

export function updateContactPreferencesFailure(): actions.UpdateContactPreferencesFailureAction {
  return {
    type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_FAILURE,
  };
}

export function updateContactPreferencesSuccessReset(): actions.UpdateContactPreferenceSuccessResetAction {
  return {
    type: ProfileActionsType.UPDATE_CONTACT_PREFERENCES_SUCCESS_RESET,
  };
}

export function getContactPreferencesRequest(): actions.GetContactPreferenceRequestAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_REQUEST,
  };
}

interface GetContactPreferencesSuccess {
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}
export function getContactPreferencesSuccess({
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
}: GetContactPreferencesSuccess): actions.GetContactPreferenceSuccessAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_SUCCESS,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
  };
}

export function getContactPreferencesFailure(): actions.GetContactPreferencesFailureAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_FAILURE,
  };
}

/// optin

export interface ContactPreferencesOptin {
  acceptGroupEmail: boolean;
  acceptPartnerEmail: boolean;
  toDoPostSuccessActionType?: string
  demandId?: string | null
  isSuccess?: boolean
}

export function getContactPreferencesOptinRequest(): actions.GetContactPreferenceOptinRequestAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_REQUEST,
  };
}

export function getContactPreferencesOptinSuccess({
  acceptGroupEmail,
  acceptPartnerEmail
}: ContactPreferencesOptin): actions.GetContactPreferenceOptinSuccessAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_SUCCESS,
    acceptGroupEmail,
    acceptPartnerEmail,
  };
}

export function getContactPreferencesOptinFailure(error: ProfileErrorState): actions.GetContactPreferencesOptinFailureAction {
  return {
    type: ProfileActionsType.GET_CONTACT_PREFERENCES_OPTIN_FAILURE,
    error
  };
}

/// setoptin
export function setContactPreferencesOptinRequest({
  demandId,
  acceptGroupEmail,
  acceptPartnerEmail,
  toDoPostSuccessActionType
}: ContactPreferencesOptin): actions.SetContactPreferencesOptInRequestAction {
  return {
    type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_REQUEST,
    demandId,
    acceptGroupEmail,
    acceptPartnerEmail,
    toDoPostSuccessActionType
  };
}

export function setContactPreferencesOptinSuccess(): actions.SetContactPreferencesOptInSuccessAction {
  return {
    type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_SUCCESS,
  };
}

export function setContactPreferencesOptinFailure(error: ProfileErrorState): actions.SetContactPreferencesOptinfailureAction {
  return {
    type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_FAILURE,
    error
  };
}

export function setContactPreferencesOptinReset(): actions.SetContactPreferencesOptInResetAction {
  return {
    type: ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_RESET,
  };
}

export function profileCheckEditMailAuthorizeIsEnableRequest(
): actions.CheckEditMailAuthorizeIsEnableRequestAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_REQUEST,
  };
}

export function profileCheckEditMailAuthorizeIsEnableSuccess(
  editMailIsEnable: boolean
): actions.CheckEditMailAuthorizeIsEnableSuccessAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_SUCCESS,
    editMailIsEnable
  };
}

export function profileCheckEditMailAuthorizeIsEnableFailure(
  error: ProfileErrorState
): actions.CheckEditMailAuthorizeIsEnableFailureAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_MAIL_AUTHORIZE_IS_ENABLE_FAILURE,
    error
  };
}

export function profileCheckEditPhoneNumberAuthorizeIsEnableRequest(
): actions.CheckEditPhoneNumberAuthorizeIsEnableRequestAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_REQUEST,
  };
}

export function profileCheckEditPhoneNumberAuthorizeIsEnableSuccess(
  editPhoneNumberIsEnable: boolean
): actions.CheckEditPhoneNumberAuthorizeIsEnableSuccessAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_SUCCESS,
    editPhoneNumberIsEnable
  };
}

export function profileCheckEditPhoneNumberAuthorizeIsEnableFailure(
  error: ProfileErrorState
): actions.CheckEditPhoneNumberAuthorizeIsEnableFailureAction {
  return {
    type: ProfileActionsType.CHECK_EDIT_PHONE_NUMBER_AUTHORIZE_IS_ENABLE_FAILURE,
    error
  };
}
