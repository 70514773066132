import axios from 'axios';

import { API_URL } from '@config/index';

const multiPartFormDataExtraHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Access-Control-Allow-Credentials': false
  }
};

// Get bankData
export function fetchGetBankData(companyId: string) {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/bankaccountdetails`);
}

// Init BankData
export function fetchInitBankDataES(companyId: string, countryCode: string, updateType: string) {
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/bankaccount/init`, {
    applyOnPrelevements: false,
    countryCode,
    updateType
  });
}

export function fetchInitBankDataRC(companyId: string, iban: string, bic: string, planId: string) {
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/bankaccount/initrc/${planId}`, {
    iban,
    bic
  });
}

// Upload Document
export function uploadDocumentBankData(companyId: string, journeyId: string, formData: FormData) {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/upload`,
    formData,
    multiPartFormDataExtraHeaders
  );
}

// Sign Mandat and Upload Iban
export function signMandatRcAndUploadIban(
  companyId: string,
  journeyId: string,
  planId: string,
  formData: FormData,
) {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/sign/${planId}`,
    formData,
    multiPartFormDataExtraHeaders
  );
}

export function fetchDownloadDocumentMandatRc(companyId: string, journeyId: string) {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/document/mandat`,
    {
      responseType: 'blob'
    }
  );
}

export function removeDocumentBankData(companyId: string, journeyId: string, documentId: string) {
  return axios.delete(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/documents/${documentId}`
  );
}

export function registerWithQuicksignOTP(companyId: string, payload: unknown, journeyId?: string) {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/register`,
    payload
  );
}

export function registerWithAllDocuments(companyId: string, payload: unknown) {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/register`,
    payload,
    multiPartFormDataExtraHeaders
  );
}

export function registerWithEREOTP(companyId: string, payload: unknown, journeyId?: string) {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/bankaccount/${journeyId}/registerWithOTP`,
    payload
  );
}
