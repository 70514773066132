import React, { useCallback, useState } from "react";
import { StyleProp, TextStyle, TouchableOpacity, View } from "react-native";

import { FontIcon, Title } from "@ere-uilib/atoms";

import { ItemState } from "../types";
import { useStyles } from "./useStyles";

interface Props {
  items?: ItemState[];
  pressedInIndex?: number;
  textStyle?: StyleProp<TextStyle>;
  onSelectItem?: (item: ItemState) => void;
  selectedItem?: {
    value: string | number;
    label: string;
  };
}

export const useRender = (props: Props | void) => {
  const {
    items,
    selectedItem,
    onSelectItem,
    textStyle,
  } = props || {};

  const styles = useStyles();
  const [pressedInIndex, setPressedInIndex] = useState<number>();

  const renderLeftItem = useCallback((item: ItemState) => {
    if (item.renderLeftItem) {
      return item.renderLeftItem();
    }

    if (item.leftIcon) {
      return (
        <View style={styles.leftIconStyle}>
          <FontIcon
            color={item.leftIcon.color}
            name={item.leftIcon.name}
            size={item.leftIcon.size}
          />
        </View>
      )
    }

    return null;
  }, [styles]);

  const renderRightItem = useCallback((item: ItemState) => {
    if (item.renderRightItem) {
      return item.renderRightItem();
    }

    if (item.rightIcon) {
      return (
        <FontIcon
          color={item.rightIcon.color}
          name={item.rightIcon.name}
          size={item.rightIcon.size}
        />
      );
    }

    return null;
  }, []);

  const renderContent = useCallback((item: ItemState) => {
    return (
      <>
        {renderLeftItem(item)}
        <View style={styles.itemTextViewStyle}>
          <Title
            style={[styles.itemTextStyle, textStyle]}
            variant="t7"
            testID={item.label.replace(/\D/g, '')}>
            {item.label}
          </Title>
        </View>
        {renderRightItem(item)}
      </>
    )
  }, [renderLeftItem, styles, renderRightItem, textStyle]);

  const renderItems = useCallback(() => {
    return (
      items?.map((item, index) => {
        const handleItemSelect = () => {
          onSelectItem?.(item);
        };

        const handlePressInIndex = () => {
          setPressedInIndex(index);
        };

        const handlePressOutIndex = () => {
          setPressedInIndex(undefined);
        };

        const style = [
          styles.itemStyle,
          (index === pressedInIndex || selectedItem?.value === item.value) &&
          styles.hoveredItem,
        ];
    
        return (
          <TouchableOpacity
            onPress={handleItemSelect}
            onPressIn={handlePressInIndex}
            onPressOut={handlePressOutIndex}
            style={style}
          >
            {renderContent(item)}
          </TouchableOpacity>
        );
      })
    )
  }, [items, onSelectItem, pressedInIndex, renderContent, selectedItem, styles,]);

  return {
    renderItems,
    renderContent,
  }
}
