import { StatusTagTypesEnum } from "@ere-uilib/molecules/tags/StatusTag/sharedInterfaces";
import { AddressTypeState } from "@modules/profile/types";
import { FormatMessageProps } from "@translations/TranslationsHooks/interfaces";
import { CardChildInfo } from "@pages/Dashboard/ProfilePage/EditProfileInformationsPage/types";
import { PlanFamillyList } from "@constants/common";
import { AddressStatusEnum } from "@constants/profile";
import { joinDefinedElements } from "@utils/joinDefinedElements";

export const generateAddressContents = (addresses: AddressTypeState[] | null, formatMessage : (message: FormatMessageProps) => string, changeRCAddressHandle: () => void, handleESAddressChange: () => void): CardChildInfo[] =>
    (addresses || []).map(item => {
 const {address, type, status} = item ?? {};
 let addressDetail: string | null = null;

 if(address) {
  const  {zipcode, country, city ,recipient,street,additionalAddress_1, additionalAddress_2, additionalAddress_3} = address;
 
const town = [zipcode, city];
const addressLines = [recipient, street, additionalAddress_1, additionalAddress_2, additionalAddress_3, joinDefinedElements(town, ' '), country]; 
 addressDetail = joinDefinedElements(addressLines, '\n');
 }

 const statusLabelId =
          (status === AddressStatusEnum.IN_PROGRESS &&
            'PersonalDetailsPagePostalAdressModificationInProgressES') ||
          'PersonalDetailsPagePostalAdressToBeCompleted';return {
  informationDescription: formatMessage({ id: `PersonalDetailsPagePostalAdressDescription${type}` }),
  informationSubDescription: type === PlanFamillyList.ES ? null : formatMessage({ id: "PersonalDetailsPagePostalAdressInformationMessageRC" }),
  userInformation: addressDetail,
  isEditable: type === PlanFamillyList.RC || (status !== AddressStatusEnum.IN_PROGRESS),
  statusType: (status === AddressStatusEnum.IN_PROGRESS && StatusTagTypesEnum.IN_PROGRESS) ||
                ((status === AddressStatusEnum.INVALID || !address) && StatusTagTypesEnum.TO_INFORM) ||
                undefined,
  statusLabel: formatMessage({ id: statusLabelId }),
  onEditPress:  type === PlanFamillyList.RC ? changeRCAddressHandle: handleESAddressChange
 };
});
