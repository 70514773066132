import {Text} from '@ere-uilib/atoms';
import {createUseStyles, useTheme} from '@ere-uilib/styles';
import {useTranslation} from '@ere-uilib/translations';
import React from 'react';
import {View} from 'react-native';
import {
    Props
} from './interfaces';
import {getStyles} from './styles';



export const SimpleSwitch = <ValueType extends unknown>({
  
  containerStyle
}:Props) => {
  const theme = useTheme();
  const styles = useStyles({
    theme,
  }, {
    containerStyle,
  });
  const { formatMessage, getMessageRaw } = useTranslation();

  return (
    <View  style={styles.containerStyle}>
      <View
        style={styles.buttonLeftStyle}>
        <Text style={{ "color": "#FFF" }}>
          {formatMessage({
            id: 'PIChoixInvestEuroLabel',
          })}
        </Text></View>
      <View
        style={styles.buttonRightStyle}>
        <Text style={{ "color": "#00915a" }}>
          {formatMessage({
            id: 'PIChoixInvestPourcentageLabel',
          })}
        </Text>
      </View>
            
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
