import React from 'react';
import { View, TouchableOpacity } from 'react-native';

import { Paragraph, Text, Title } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/translations';

import { DesktopMenuUserInformationsProps } from './interface';
import { useStyles } from './styles';

export const DesktopMenuUserInformationsComponent = ({
  decodedToken,
  onPressLogoutButton,
  userInfo,
}: DesktopMenuUserInformationsProps) => {

  const firstName = decodedToken?.given_name ?? userInfo.givenName;
  const lastName = decodedToken?.family_name ?? userInfo.familyName;
  const username = `${firstName} ${lastName}`;
  const lastConnectionDateApiKey = 'https://login.ere.bnpparibas/claims/lastconnection'
  const lastConnectionDate = decodedToken?.[lastConnectionDateApiKey] ?? userInfo[lastConnectionDateApiKey];
  const styles = useStyles();


  const { formatMessage, formatDate, getLocal } = useTranslation();
  const isLocalAr = getLocal() === 'ar'

  return (
    <View>
      <View style={styles.userInformationContainer}>
        <Title
          style={styles.userInformationTitle}
          testId='dashboard_username'
          variant="t6"
          weight='bold'>
          {username}
        </Title>
        <Paragraph
          style={styles.userInformationSubtitle}
          testId='dashboard_last_connexion'
          variant="t4"
          weight="light">
          {formatMessage({
            id: 'menu_last-connexion', values:
            {
              variable:
                formatDate({
                  value: lastConnectionDate,
                  options: {
                    dateStyle: 'short',
                  },
                })
            },
          })}
          {!isLocalAr && ` ${formatMessage({ id: 'Profil_LoginHour_Prefix_label' })} `}
          {!isLocalAr && formatDate({
            value: lastConnectionDate,
            options: {
              timeStyle: 'short'
            }
          })
          }
        </Paragraph>

        <TouchableOpacity
          onPress={onPressLogoutButton}
          >
          <Text
            style={styles.deconnectionButton}
            testId='dashboard_logout'
            variant={"t2"}
          >
            {formatMessage({ id: 'TermsetFrontProfileMenuDeconnexion' })}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
