import { REACT_APP_AES_KEY } from '@config/index';
import { OtpChanelTypeEnum } from '@constants/profile';
import { initialState } from '@modules/profile/reducer';
import { AddressTypeState, OtpParametersState, ProfileUserInfosState } from '@modules/profile/types';
import { AppState } from '@modules/reducers';
import { decryptData } from '@utils/encryptDecryptData';

export const getIsAskAgainSuccess = (state: AppState) => state.profile.otpFlow.isAskAgainSuccess;

export const getOtpError = (state: AppState) => state.profile.otpError;

export const getConsents = (state: AppState) => state.profile.consents;

export const getProfileError = (state: AppState) => state.profile.error;

export const getEditPasswordError = (state: AppState) => state.profile.editPasswordError;

export const getProfileMenu = (state: AppState) => state.profile.profileMenu;

export const getProfileUserInfos = (state: AppState): ProfileUserInfosState =>
  decryptData(state.profile.userInfos, REACT_APP_AES_KEY) || initialState.userInfos;
export const getProfilAddresses = (state: AppState): { addresses: AddressTypeState[] | null } =>
  state.profile.address;
export const getOtpParameters = (state: AppState): OtpParametersState | null =>
  state.profile.otpParameters;

export const getIsProfilEditPasswordSuccess = (state: AppState) =>
  state.profile.isProfilEditPasswordSuccess;

export const getIsUpdateContactPreferenceSuccess = (state: AppState) =>
  state.profile.contactPreference.isUpdateSuccess;

export const getContactPreferenceHasNotificationsActivated = (state: AppState) =>
  state.profile.contactPreference.hasNotificationsActivated;

export const getContactPreferencesSuccessOptinGroupEmail = (state: AppState) =>
  state.profile.contactPreferencesOptin.acceptGroupEmail;

export const getContactPreferencesSuccessOptinPartnerEmail = (state: AppState) =>
  state.profile.contactPreferencesOptin.acceptPartnerEmail;

export const getIsUpdateContactPreferenceOptinSuccess = (state: AppState) =>
  state.profile.contactPreferencesOptin.isSuccess;

export const getContactPreferenceSuccessOptInEmail = (state: AppState) =>
  state.profile.contactPreference.emailOptIn;

export const getContactPreferenceSuccessOptInSms = (state: AppState) =>
  state.profile.contactPreference.smsOptIn;

export const getChanelOtpIdentifier = (state: AppState): string | undefined => {
  // try to get current targeted channel otpIdentifier
  const otpParameters = state.profile.otpParameters;
  let otpIdentifier: string | undefined;
  switch (otpParameters?.chanelType) {
    case OtpChanelTypeEnum.MAIL:
    case OtpChanelTypeEnum.MAIL_DOCAPOSTE:
      otpIdentifier = otpParameters?.otpIdentifierMail;
      break;
    case OtpChanelTypeEnum.SMS:
    case OtpChanelTypeEnum.SMS_DOCAPOSTE:
      otpIdentifier = otpParameters?.otpIdentifierSms;
      break;
    default:
      break;
  }
  return otpIdentifier;
};

export const getIsBiometricLockedFromProfileMenu = (state: AppState) =>
  state.profile.isBiometricLockedFromProfileMenu;

export const getEditorialContent = (state: AppState) => state.profile.editorialContentPage[0];

export const getBankData = (state: AppState) => state.profile.bankData;

export const getEditMailIsEnable = (state: AppState) => state.profile.editMailIsEnable;

export const getEditPhoneNumberIsEnable = (state: AppState) => state.profile.editPhoneNumberIsEnable;
