import React, { Fragment, useCallback } from 'react';
import { Image, ListRenderItem, View, Text } from 'react-native';
import { Card, Carousel, RetryActionPlaceHolder } from '@components/ERE-UILib';
import { HtmlRenderer } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';
import { OCDState } from '@ere-uilib/types';
import { useStyles } from './useStyles';
import { Props } from './interface';
import { OCDLoader } from './components';


export const OCDCard: React.FC<Props> = ({
  Cards,
  cardSize,
  containerStyle,
  imageStyle,
  ocdContainer,
  ocdLoadingBlocStyle,
  ocdTextContentContainer,
  error,
  onGetOCD,
  isLoading,
  spacingBeforeFirstItem,
  spacingAfterLastItem
}: Props) => {
  const { isMobile } = useScreenSizes();
  const styles = useStyles();

  const getImageUrl = useCallback(
    (item: OCDState, cardSize?: string) => {
      let img = '';

      if (item.position === 1) {
        if (isMobile) {
          img = item.image1SmallUrl;
        } else {
          switch (cardSize) {
            case 'small':
              img = item.image1SmallUrl;
              break;
            default:
              img = item.image1LargeUrl;
              break;
          }
        }
      } else if (item.position === 2) {
        if (isMobile) {
          img = item.image2SmallUrl;
        } else {
          switch (cardSize) {
            case 'small':
              img = item.image2SmallUrl;
              break;
            default:
              img = item.image2LargeUrl;
              break;
          }
        }
      }

      return img;
    },
    [isMobile]
  );

  const renderItem = useCallback<ListRenderItem<OCDState>>(
    ({ item }) => {
      const imageUrl = getImageUrl(item, cardSize);
      return (
        <View
          style={[styles.ocdContainer, { backgroundColor: item.backgroundColor }, ocdContainer]}>
          {!!imageUrl && (
            <Image
              resizeMode="stretch"
              source={{
                uri: imageUrl
              }}
              style={[styles.imageStyle, imageStyle]}
            />
          )}
          <View style={[styles.ocdTextContentContainer, ocdTextContentContainer]}>
            <HtmlRenderer html={item.contenu} />
          </View>
        </View>
      );
    },
    [cardSize, getImageUrl, imageStyle, ocdContainer, ocdTextContentContainer, styles]
  );

  const renderLoader = useCallback(() => {
    if (!isLoading) return null;

    return (
      <Card
        containerStyle={[
          styles.ocdLoadingBlocStyle,
          { marginHorizontal: spacingBeforeFirstItem },
          ocdLoadingBlocStyle
        ]}>
        <OCDLoader />
      </Card>
    );
  }, [
    isLoading,
    ocdLoadingBlocStyle,
    spacingBeforeFirstItem,
  ])

  const renderRetryAction = useCallback(() => {
    if (!error?.message) return null;

    return (
      <RetryActionPlaceHolder
        containerStyle={containerStyle}
        retryAction={onGetOCD}
      />
    );
  }, [
    error,
    containerStyle,
    onGetOCD
  ]);

  const renderCarousel = useCallback(() => {
    if (isLoading || error?.message || !Cards?.length) return null;

    return (
      <Carousel<OCDState>
        arrowsSize="large"
        containerStyle={containerStyle}
        data={Cards}
        keyExtractor={(item, index) => 'ocd' + item.position + index}
        renderItem={renderItem}
        spacingAfterLastItem={spacingBeforeFirstItem}
        spacingBeforeFirstItem={spacingAfterLastItem}
      />
    );
  }, [
    error,
    Cards,
    containerStyle,
    isLoading,
    spacingBeforeFirstItem,
    spacingAfterLastItem,
    renderItem
  ])

  return (
    <Fragment>
      {renderLoader()}
      {renderRetryAction()}
      {renderCarousel()}
    </Fragment>
  )
};
