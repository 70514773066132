import React, { useCallback, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  CompareFundsModal,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  ProfitSharingIncentiveAmountFieldModalConnected,
  ProfitSharingIncentiveAmountFieldModalConnectedRefType,
  SimpleButton,
  Text,
  useScreenSizes,
  useTranslation,
  ProfitSharingIncentiveRetirementAmountValidationConnected,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { RefundRepartitionSupportsState } from '@modules/refund/available-refund/types';
import { ModalConsentValidation } from '../components/ModalConsentValidation';
import { ProfitSharingIncentiveRepartitionProps } from './interfaces';
import { useStyles } from './styles';
import { label } from './label';

import {
    ProfitSharringRepartitionProvider
} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";
import {BottomActions} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/BottomActions";
import {LoaderLayer} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/LoaderLayer";
import {SubHeader} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/SubHeader";
import {Header} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/Header";
import PlansList from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/PlansList";

export const ProfitSharingIncentiveRepartition: React.FC<React.PropsWithChildren<ProfitSharingIncentiveRepartitionProps>> = ({
  navigation,
  initData,
  repartition,
  assigmentAmounts,
  investedProjectPlans,
  investedRetirementPlans,
  allSupportsToCompare,
  setProfitSharingIncentiveRepartitionAmount,
  isContributionError,
  isContributionLoading
}) => {
    const amountModalRef = useRef<ProfitSharingIncentiveAmountFieldModalConnectedRefType>(null)
    const { formatMessage } = useTranslation();

    const { isDesktop } = useScreenSizes();
    const styles = useStyles();
    const [displayCompareFundsModal, setDisplayCompareFundsModal] =
      useState(false);
    const [fundsToCompare, setFundsToCompare] = useState<
      RefundRepartitionSupportsState[] | undefined
    >();
    const [fundSheetModalData, setFundSheetModalData] =
      useState<FundSheetModalDataType | null>();
    const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);

    const [displayModalConsentValidation, setDisplayModalConsentValidation] =
      useState(false);

    const handleModalValidation = useCallback(() => {
      setDisplayModalConsentValidation(false);
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveSynthesis,
      });
    }, [navigation]);

    return (
      <DashboardPageWrapperConnected cardContentStyle={styles.dashboardWrapper}>
          <ProfitSharringRepartitionProvider repartition={repartition} assigmentAmounts={assigmentAmounts}>
              <ColumnsContainer
                  renderHeader={() => <Header initData={initData} /> }
                  renderRightDesktopColumn={() => <SubHeader/>}
                  renderStickyMobileBottom={() => <BottomActions navigation={navigation} isContributionLoading={isContributionLoading} setDisplayModalConsentValidation={setDisplayModalConsentValidation} />}
              >
                  {(allSupportsToCompare?.supports?.length || 0) > 1 && (
                      <View style={styles.compareButtonContainerStyle}>
                          <SimpleButton
                              containerStyle={styles.compareButtonStyle}
                              design="outlined"
                              onPress={() => {
                                  setFundsToCompare(allSupportsToCompare?.supports);
                                  setDisplayCompareFundsModal(true);
                              }}

                              size="small"
                              title={formatMessage({
                                  id: label.button.compare,
                              })}
                          />
                      </View>
                  )}
                  {!!investedProjectPlans && investedProjectPlans.length > 0 && (
                      <>
                          <Text
                              variant="t3"
                              weight="bold">
                              {formatMessage({ id: label.section.project.title })}
                          </Text>
                          <Text
                              style={{ marginVertical: 15 }}
                              variant="t3"
                              weight="light">
                              {formatMessage({ id: label.section.project.subtitle })}
                          </Text>
                          <View style={styles.cardContainer}>
                              <PlansList
                                  initData={initData}
                                  plans={investedProjectPlans}
                                  setProfitSharingIncentiveRepartitionAmount={setProfitSharingIncentiveRepartitionAmount}
                                  isContributionError={isContributionError}
                                  navigation={navigation}
                                  amountModalRef={amountModalRef} />
                          </View>
                      </>
                  )}
                  {!!investedRetirementPlans && investedRetirementPlans.length > 0 && (
                      <>
                          <Text
                              style={{ marginTop: 15 }}
                              variant="t3"
                              weight="bold">
                              {formatMessage({ id: label.section.retirement.title })}
                          </Text>
                          <Text
                              style={{ marginVertical: 15 }}
                              variant="t3"
                              weight="light">
                              {formatMessage({ id: label.section.retirement.subtitle })}
                          </Text>
                          <View style={styles.cardContainer}>
                              <PlansList
                                  initData={initData}
                                  plans={investedRetirementPlans}
                                  setProfitSharingIncentiveRepartitionAmount={setProfitSharingIncentiveRepartitionAmount}
                                  isContributionError={isContributionError}
                                  navigation={navigation}
                                  amountModalRef={amountModalRef} />
                          </View>
                      </>
                  )}
                  {fundSheetModalData && (
                      <FundSheetModal
                          id={fundSheetModalData?.id}
                          modalIsVisible={displayFundSheetModal}
                          onCloseModal={() => {
                              setDisplayFundSheetModal(false);
                              setFundSheetModalData(null);
                          }}
                          title={fundSheetModalData?.title}
                      />
                  )}
                  {isDesktop && <BottomActions navigation={navigation} isContributionLoading={isContributionLoading} setDisplayModalConsentValidation={setDisplayModalConsentValidation} />}
                  <LoaderLayer isContributionLoading={isContributionLoading}/>
              </ColumnsContainer>
              <CompareFundsModal
                  fundsToCompare={fundsToCompare?.map(fund => {
                      return {
                          isinCode: fund.supportIsin,
                          supportName: fund.supportName,
                          addedValue: 0,
                          hasContribution: fund.hasContribution,
                          amount: fund.amount.amount,
                          hasNoFundSheet: fund.hasNoFundSheet,
                          source: fund.source
                      };
                  })}
                  modalIsVisible={displayCompareFundsModal}
                  onCloseModal={() => {
                      setDisplayCompareFundsModal(false);
                  }}
              />
              <ProfitSharingIncentiveAmountFieldModalConnected
                  ref={amountModalRef}
              />
              <ModalConsentValidation
                  modalIsVisible={displayModalConsentValidation}
                  onModalClose={() => {
                      setDisplayModalConsentValidation(false);
                  }}
                  onModalValidation={handleModalValidation}
              />

              <ProfitSharingIncentiveRetirementAmountValidationConnected />
          </ProfitSharringRepartitionProvider>
      </DashboardPageWrapperConnected>
    );
  };
