import {
  SimpleSwitchStyles,
  SimpleSwitchStylesContext
} from './interfaces';
  
export const getStyles = (
  context?: SimpleSwitchStylesContext,
  style?: SimpleSwitchStyles,
): SimpleSwitchStyles => ({
  buttonRightStyle: [
    {
      paddingVertical: context?.theme.metrics.spacing.xs,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      backgroundColor: "#FFF",
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
    style?.buttonRightStyle
  ],
  buttonLeftStyle: [
    {
      paddingVertical: context?.theme.metrics.spacing.xs,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      backgroundColor: context?.theme.colors.basics.primary.c500,
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.white
   
    },
    style?.buttonLeftStyle
  ],
  containerStyle: [
    {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: context?.theme.metrics.spacing.l,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.primary.c500,
      backgroundColor: context?.theme.colors.basics.white,
      minHeight: 30,
      position: "absolute",
      right: "2%",
      marginTop: context?.theme.metrics.spacing.s,
        
    },
    style?.containerStyle,
  ],
  segment: [
    {
      flexShrink: 1,
      flexGrow: 0,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
      paddingHorizontal: context?.theme.metrics.spacing.m,
    },
    style?.segment,
  ],
  activeSegment: [
    {
      zIndex: 5,
      borderRadius: context?.theme.metrics.spacing.l,
      position: 'absolute',
      height: '100%',
      backgroundColor: context?.theme.colors.basics.primary.c500,
    },
    style?.activeSegment,
  ],
  defaultText: [
    {
      color: context?.theme.colors.basics.primary.c500,
      fontSize: context?.theme.fonts.fontSize.button.t3,
    },
    style?.defaultText,
  ],
  activeText: [
    {
      color: context?.theme.colors.basics.white,
      fontSize: context?.theme.fonts.fontSize.button.t3,
    },
    style?.activeText,
  ],
});
  