import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme, useScreenSizes } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes()

  const styles = useMemo(() => StyleSheet.create({
    contentContainer: {
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth
    },
    stepper: {
      marginBottom: theme.metrics.spacing.xxxxl
    },
    stepCustomContainer: {
      marginTop: - theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
      paddingBottom: theme.metrics.spacing.m,
    },
    stepNotification: {
      padding: 0,
      marginTop: theme.metrics.spacing.xs,
      paddingBottom: theme.metrics.spacing.s
    },
    headerContainer: {
      height: 54,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.colors.basics.white,
      ...(isDesktop ? {
        paddingHorizontal: theme.metrics.spacing.xl,
      }:{
        paddingHorizontal: theme.metrics.spacing.s,
      })
    },
    headerMiddleContainer: {
      flexGrow: 1,
    },
    headerRightContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerCloseButton: {
      width: theme.metrics.iconSizes.xxm,
      height: theme.metrics.iconSizes.xxm,
    },
    button: {
      ...(isDesktop && {
        maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth
      })
    }
  }), [theme, isDesktop]);

  return styles;
}
