import { formmatNewsFeedOCD } from '@modules/utils/ocdMethods';

import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { HistoryState } from './types';
import { HistoryActions, HistoryActionsType } from './actions/historyActionsTypes';
import { AccountsAction, DashboardActionsType } from '../dashboard/actions/dashboardActionsTypes';

export const initialState: HistoryState = {
  newsFeed: [],
  historyError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};

export default function ProfileReducer(
  state: HistoryState = initialState,
  action: HistoryActions | AuthenticateAction | AccountsAction
): HistoryState {
  switch (action?.type) {
  case HistoryActionsType.GET_NEWS_FEED_REQUEST:
    return {
      ...state,
      historyError: initialState.historyError
    };
  case HistoryActionsType.GET_NEWS_FEED_SUCCESS:
    return {
      ...state,
      newsFeed: formmatNewsFeedOCD(action.newsFeed)
    };
  case HistoryActionsType.GET_NEWS_FEED_FAILURE:
    return {
      ...state,
      historyError: action.error
    };
  case HistoryActionsType.INIT_NEWS_FEED_LIST:
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
  case DashboardActionsType.PUT_SELECTED_COMPANY_SUCCESS:
    return initialState;
  default:
    return state;
  }
}
