import React from "react";
import {View} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import {SimpleButton} from "@ere-uilib/molecules";
import {IconEnum} from "@ere-uilib/enums";
import {label} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/label";
import {locator} from "@constants/locator";
import {useStyles} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/styles";
import {useTranslation} from "@components/ERE-UILib";
import {useProfitSharringRepartitionContext} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";
import {LoaderLayer} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/LoaderLayer";
import {AppNavigatorInterface} from "@navigation/Interfaces";

type BottomActionsType  = {
    navigation: StackNavigationProp<AppNavigatorInterface>;
    setDisplayModalConsentValidation: (value: boolean) => void;
    isContributionLoading?: boolean
}

export const BottomActions: React.FC<BottomActionsType> = ({navigation, setDisplayModalConsentValidation, isContributionLoading}) => {
    const styles = useStyles();
    const { formatMessage } = useTranslation();
    const { isformFilled } = useProfitSharringRepartitionContext();

    return (
        <View style={styles.bottomActionsStyle}>
            <View style={styles.bottomActionsButtonsContainerStyle}>
                <SimpleButton
                    design="outlined"
                    leftIcon={IconEnum.CHEVRON_LEFT}
                    onPress={() => navigation.goBack()}
                />
                <SimpleButton
                    containerStyle={styles.simpleButtonStyle}
                    design="solid"
                    disabled={!isformFilled}
                    onPress={() => {
                        setDisplayModalConsentValidation(true);
                    }}
                    size="small"
                    title={formatMessage({ id: label.button.next })}
                    testId={locator._pi._synthese_continu}
                />
            </View>
           <LoaderLayer isContributionLoading={isContributionLoading}/>
        </View>
    )
}
