import {
  AnnuityNavigatorRouteNames,
  AppNavigatorRouteNames,
  ArbitrationNavigatorRouteNames,
  BottomTabNavigatorRouteNames,
  DocumentSpaceNavigatorRouteNames,
  ForgetPasswordNavigatorRouteNames,
  HistoryNavigatorRouteNames,
  InstallmentNavigatorRouteNames,
  OtpSecurisationNavigatorRouteNames,
  OtpValidationNavigatorRouteNames,
  ProfileStackNavigatorRouteNames,
  ProfitSharingIncentiveNavigatorRouteNames,
  RefundsNavigatorRouteNames,
  RelationShipEnteringNavigatorRouteNames,
  RetirementNavigatorRouteNames,
  SavingsNavigatorRouteNames,
  SelfcareNavigatorRouteNames,
  BankDetailsNavigatorRouteNames,
} from '@navigation/Interfaces';

export const RouteNames = {
  ...AppNavigatorRouteNames,
  ...BottomTabNavigatorRouteNames,
  ...ForgetPasswordNavigatorRouteNames,
  ...OtpSecurisationNavigatorRouteNames,
  ...OtpValidationNavigatorRouteNames,
  ...RelationShipEnteringNavigatorRouteNames,
  ...ProfileStackNavigatorRouteNames,
  ...SavingsNavigatorRouteNames,
  ...InstallmentNavigatorRouteNames,
  ...RefundsNavigatorRouteNames,
  ...HistoryNavigatorRouteNames,
  ...ArbitrationNavigatorRouteNames,
  ...SelfcareNavigatorRouteNames,
  ...ProfitSharingIncentiveNavigatorRouteNames,
  ...DocumentSpaceNavigatorRouteNames,
  ...AnnuityNavigatorRouteNames,
  ...RetirementNavigatorRouteNames,
  ...BankDetailsNavigatorRouteNames
};
