import { PlanFamillyList } from '@constants/index';

import {
  BankDataType,
  BankDetailsErrorState,
  BankDetailsInitType,
  FileData,
  RegisterRulesType,
  PopinContentTypeEnum,
} from '../types';

export enum BankDetailsActionsType {
  GET_BANK_DATA_REQUEST = 'GET_BANK_DATA_REQUEST',
  GET_BANK_DATA_SUCCESS = 'GET_BANK_DATA_SUCCESS',
  GET_BANK_DATA_FAILURE = 'GET_BANK_DATA_FAILURE',
  CLEAR_BANK_DATA = 'CLEAR_BANK_DATA',
  INIT_BANK_DATA_REQUEST = 'INIT_BANK_DATA_REQUEST',
  INIT_BANK_DATA_SUCCESS = 'INIT_BANK_DATA_SUCCESS',
  INIT_BANK_DATA_RC_SUCCESS = 'INIT_BANK_DATA_RC_SUCCESS',
  INIT_BANK_DATA_FAILURE = 'INIT_BANK_DATA_FAILURE',
  UPLOAD_DOCUMENT_BANK_DATA_REQUEST = 'UPLOAD_DOCUMENT_BANK_DATA_REQUEST',
  UPLOAD_DOCUMENT_BANK_DATA_SUCCESS = 'UPLOAD_DOCUMENT_BANK_DATA_SUCCESS',
  UPLOAD_DOCUMENT_BANK_DATA_FAILURE = 'UPLOAD_DOCUMENT_BANK_DATA_FAILURE',
  DOWNLOAD_DOCUMENT_MANDAT_RC_REQUEST = 'DOWNLOAD_DOCUMENT_MANDAT_RC_REQUEST',
  DOWNLOAD_DOCUMENT_MANDAT_RC_SUCCESS = 'DOWNLOAD_DOCUMENT_MANDAT_RC_SUCCESS',
  DOWNLOAD_DOCUMENT_MANDAT_RC_FAILURE = 'DOWNLOAD_DOCUMENT_MANDAT_RC_FAILURE',
  SIGN_MANDAT_RC_REQUEST = 'SIGN_MANDAT_RC_REQUEST',
  SIGN_MANDAT_RC_SUCCESS = 'SIGN_MANDAT_RC_SUCCESS',
  SIGN_MANDAT_RC_FAILURE = 'SIGN_MANDAT_RC_FAILURE',
  REMOVE_DOCUMENT_BANK_DATA_REQUEST = 'REMOVE_DOCUMENT_BANK_DATA_REQUEST',
  REMOVE_DOCUMENT_BANK_DATA_SUCCESS = 'REMOVE_DOCUMENT_BANK_DATA_SUCCESS',
  REMOVE_DOCUMENT_BANK_DATA_FAILURE = 'REMOVE_DOCUMENT_BANK_DATA_FAILURE',
  REGISTER_BANK_DATA_REQUEST = 'REGISTER_BANK_DATA_REQUEST',
  REGISTER_BANK_DATA_SUCCESS = 'REGISTER_BANK_DATA_SUCCESS',
  REGISTER_BANK_DATA_FAILURE = 'REGISTER_BANK_DATA_FAILURE',
  SET_SELECTED_PLAN_FAMILY = 'SET_SELECTED_PLAN_FAMILY',
  SET_SELECTED_PLAN_ID = 'SET_SELECTED_PLAN_ID',
  SET_SELECTED_DOMICILIATION_COUNTRY = 'SET_SELECTED_DOMICILIATION_COUNTRY',
  SET_SELECTED_ACCOUNT_TYPE = 'SET_SELECTED_ACCOUNT_TYPE',
  SET_REGISTER_RULES = 'SET_REGISTER_RULES',
  SET_UPDATE_FINISHED = 'SET_UPDATE_FINISHED'
}

export interface SetRegisterRulesAction {
  type: BankDetailsActionsType.SET_REGISTER_RULES;
  registerRules: RegisterRulesType;
}

export interface SetUpdateFinishedAction {
  type: BankDetailsActionsType.SET_UPDATE_FINISHED;
  updateFinished: PopinContentTypeEnum | undefined;
}

export interface SetSelectedPlanFamily {
  type: BankDetailsActionsType.SET_SELECTED_PLAN_FAMILY;
  selectedPlanFamily: PlanFamillyList | undefined;
}

export interface SetSelectedPlanId {
  type: BankDetailsActionsType.SET_SELECTED_PLAN_ID;
  selectedPlanId: string | undefined;
}

export interface SetSelectedDomiciliationCountryAction {
  type: BankDetailsActionsType.SET_SELECTED_DOMICILIATION_COUNTRY;
  selectedCountry: string | null;
}

export interface SetSelectedAccountTypeAction {
  type: BankDetailsActionsType.SET_SELECTED_ACCOUNT_TYPE;
  accountType: string;
}

// Get bankData

export interface GetBankDataRequestAction {
  type: BankDetailsActionsType.GET_BANK_DATA_REQUEST;
}

export interface GetBankDataSuccessAction {
  type: BankDetailsActionsType.GET_BANK_DATA_SUCCESS;
  bankData: BankDataType;
}

export interface GetBankDataFailureAction {
  type: BankDetailsActionsType.GET_BANK_DATA_FAILURE;
  error: BankDetailsErrorState;
}

// Init BankData
export type InitBankDataRequestAction = {
  type: BankDetailsActionsType.INIT_BANK_DATA_REQUEST;
  iban?: string;
  bic?: string;
};

export interface InitBankDataSuccessAction {
  type: BankDetailsActionsType.INIT_BANK_DATA_SUCCESS;
  initData: BankDetailsInitType;
  isRc: boolean
}

export interface InitBankDataFailureAction {
  type: BankDetailsActionsType.INIT_BANK_DATA_FAILURE;
  error: BankDetailsErrorState | null;
}

// Upload Document
export interface UploadDocumentBankDataRequestAction {
  type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_REQUEST;
  documentKey: string;
  file: FileData;
}

export interface UploadDocumentBankDataSuccessAction {
  type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_SUCCESS;
  documentKey: string;
  documentId: string;
  file: FileData;
}

export interface UploadDocumentBankDataFailureAction {
  type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_FAILURE;
  documentKey: string;
}

export interface DownloadDocumentMandatRcRequestAction {
  type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_REQUEST;
}

export interface DownloadDocumentMandatRcSuccessAction {
  type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_SUCCESS;
  documentBlob: string | undefined;
}

export interface DownloadDocumentMandatRcFailureAction {
  type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_FAILURE;
  error: Error;
}

export interface SignMandatRcRequestAction {
  type: BankDetailsActionsType.SIGN_MANDAT_RC_REQUEST;
  selectedOTPChannel: string;
}

export interface SignMandatRcSuccessAction {
  type: BankDetailsActionsType.SIGN_MANDAT_RC_SUCCESS;
}


export interface SignMandatRcFailureAction {
  type: BankDetailsActionsType.SIGN_MANDAT_RC_FAILURE;
  error: BankDetailsErrorState;
}

export interface RemoveDocumentBankDataRequestAction {
  type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_REQUEST;
  documentKey: string;
}

export interface RemoveDocumentBankDataSuccessAction {
  type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_SUCCESS;
  documentKey: string;
}

export interface RemoveDocumentBankDataFailureAction {
  type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_FAILURE;
  documentKey: string;
}

export interface RegisterBankDataRequestAction {
  type: BankDetailsActionsType.REGISTER_BANK_DATA_REQUEST;
  iban: string;
  bic: string;
}

export interface RegisterBankDataSuccessAction {
  type: BankDetailsActionsType.REGISTER_BANK_DATA_SUCCESS;
  quickSignUrl: string | null;
}

export interface RegisterBankDataFailureAction {
  type: BankDetailsActionsType.REGISTER_BANK_DATA_FAILURE;
  error: BankDetailsErrorState;
}

export interface ClearBankDataAction {
  type: BankDetailsActionsType.CLEAR_BANK_DATA;
}

export type BankDetailsAction =
  | GetBankDataRequestAction
  | GetBankDataSuccessAction
  | GetBankDataFailureAction
  | InitBankDataRequestAction
  | InitBankDataSuccessAction
  | InitBankDataFailureAction
  | UploadDocumentBankDataRequestAction
  | UploadDocumentBankDataSuccessAction
  | UploadDocumentBankDataFailureAction
  | DownloadDocumentMandatRcRequestAction
  | DownloadDocumentMandatRcSuccessAction
  | DownloadDocumentMandatRcFailureAction
  | SignMandatRcRequestAction
  | SignMandatRcSuccessAction
  | SignMandatRcFailureAction
  | ClearBankDataAction
  | RemoveDocumentBankDataRequestAction
  | RemoveDocumentBankDataSuccessAction
  | RemoveDocumentBankDataFailureAction
  | RegisterBankDataRequestAction
  | RegisterBankDataSuccessAction
  | RegisterBankDataFailureAction
  | SetSelectedPlanFamily
  | SetSelectedPlanId
  | SetSelectedDomiciliationCountryAction
  | SetSelectedAccountTypeAction
  | SetRegisterRulesAction
  | SetUpdateFinishedAction;
